import React, { useState, useEffect } from 'react';
import {
  API_CODE,
  CARD_ERROR,
  FEES,
  EXTERNAL_LINKS,
  REGEX,
  EMAIL_JS,
  PAYMENT_METHOD,
  MUI_COLORS,
  COMPANY_REGISTRATION_COUNTRY,
  SESSION_STORAGE_NAME
} from '../../../constants/enum.js';
import PropTypes from 'prop-types';
import Aes from '../../../images/graphics/registerCompany/aes.png';
import Ssl from '../../../images/graphics/registerCompany/ssl.png';
import Stripe from '../../../images/graphics/registerCompany/stripe.png';
import * as userActions from '../../account-setup/reduxStore/action';
import XSText from '../../ui/typography/XSText.js';
import TextInput from '../../ui/inputs/TextInput';
import { FormControl } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import ValidationMessage from '../../ui/ValidationMessage';
import CreditCardInline from '../../../images/icons/inline/credit-card.inline.svg';
import PayPalInline from '../../../images/icons/inline/pay-pal.inline.svg';
import '../../../styles/pages/new-company-signup/submit.scss';
import BackButton from '../../ui/buttons/BackButton.js';
import {
  defaultValidationMessage,
  expriyDateFormatter,
  handleEnter,
  isBrowser,
  isValidMonthAndYear
} from '../../../helper/helpers.js';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import TermCondition from './TermCondition.js';
import { connect } from 'react-redux';
import * as actionsForRegistrationNewCompany from '../redux/action';
import { navigate } from 'gatsby';
import StatrysLuckySpinner from '../../../components/ui/loaders/StatrysLucky';
import MobileNumberAutoComplete from '../../ui/inputs/MobileNumberAutoComplete.js';
import * as lpn from 'google-libphonenumber';
import DynamicModal from '../../../components/ui/modal/DynamicModal';
import MSText from '../../ui/typography/MSText';
import MDSubtitleText from '../../ui/typography/MDSubtitleText.js';
import { send } from '@emailjs/browser';
import Visa from '../../../images/graphics/paymentCardType/visa.png';
import MasterCard from '../../../images/graphics/paymentCardType/masterCard.png';
import Lines from '../../../images/graphics/paymentCardType/lines.png';
import Amex from '../../../images/graphics/paymentCardType/amex.png';
import PaypalCheckoutButton from './PayPalCheckout.js';
import CardErrorPNG from '../../../images/graphics/registerCompany/card-error.png';
import StepsIndicator from '../../ui/StepsIndicator.js';
import axios from 'axios';
import { useLocation } from '@reach/router';

let cardTypes = [
  {
    image: Visa,
    alt: 'visa'
  },
  {
    image: MasterCard,
    alt: 'MasterCard'
  },
  {
    image: Lines,
    alt: 'Lines'
  },
  {
    image: Amex,
    alt: 'Amex'
  }
];

function PaymentUI({
  checkout,
  companyId,
  dialCodes,
  countryDialCodes,
  companyUpdate,
  allStakeHolder,
  newCompanyAllInfo,
  prevStep,
  registrationCountry
}) {
  const { handleSubmit, register, errors, setValue, watch } = useForm();
  const [expirationDate, setExpirationDate] = useState(
    JSON.parse(sessionStorage.getItem(SESSION_STORAGE_NAME.NEW_COMPANY_ALL_INFO))?.expirationDate
      ? JSON.parse(sessionStorage.getItem(SESSION_STORAGE_NAME.NEW_COMPANY_ALL_INFO))?.expirationDate
      : ''
  );
  const [cardNumberValue, setCardNumberValue] = useState(
    JSON.parse(sessionStorage.getItem(SESSION_STORAGE_NAME.NEW_COMPANY_ALL_INFO))?.cardNumberValue
      ? JSON.parse(sessionStorage.getItem(SESSION_STORAGE_NAME.NEW_COMPANY_ALL_INFO))?.cardNumberValue
      : ''
  );
  const [cvcNumber, setCvcNumber] = useState(
    JSON.parse(sessionStorage.getItem(SESSION_STORAGE_NAME.NEW_COMPANY_ALL_INFO))?.cvcNumber
      ? JSON.parse(sessionStorage.getItem(SESSION_STORAGE_NAME.NEW_COMPANY_ALL_INFO))?.cvcNumber
      : ''
  );
  const [loading, setLoading] = useState(false);
  const [payPalLoading, setPayPalLoading] = useState(false);
  const [mobileNumberError, setMobileNumberError] = useState({ countryError: false });
  const [cardsError, setCardsError] = useState({ cardNumber: false, expiration: false, cvc: false });
  const [emailError, setEmailError] = useState(false);
  const [userAndCardDetails, setUserAndCardDetails] = useState({
    email: '',
    cardNumber: '',
    cvc: ''
  });

  const [emailJsErrorModal, setEmailJsErrorModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [emailJsResponse, setEmailJsResponse] = useState({});
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHOD?.CREDIT_CARD);
  const phoneUtil = lpn.PhoneNumberUtil.getInstance();
  const emailRegex = REGEX.EMAIL_REGEX;
  const expriration = (date) => {
    setExpirationDate(expriyDateFormatter(date));
    setValue('expiration', expriyDateFormatter(date));
  };

  const getCountryDialCodes = async () => {
    await countryDialCodes();
  };
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const intentId = params.get('payment_intent');

  const checkPayment = async () => {
    if (intentId) {
      setLoading(true);
      try {
        const checkPaymentResponse = await axios.post(`${process.env.BASE_API_URL}company/updatePayment`, {
          companyId: companyId,
          email: newCompanyAllInfo.email.trim(),
          intentId: intentId,
          registrationCountry: registrationCountry
        });
        if (checkPaymentResponse?.data?.statusCode === API_CODE?.STATUS_200) {
          navigate(
            `${EXTERNAL_LINKS.INVOICE_COMPANY_SETUP_URL}?companyId=${companyId}&email=${newCompanyAllInfo.email}`
          );
          sessionStorage.removeItem(SESSION_STORAGE_NAME.NEW_COMPANY_ALL_INFO);
        } else {
          setPaymentModal(true);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setPaymentModal(true);
      }
    }
  };

  useEffect(() => {
    checkPayment();
    getCountryDialCodes();
  }, []);

  const changeTextInput = (e) => {
    setCardsError((prevState) => {
      return {
        ...prevState,
        [e.target.name]: false
      };
    });
  };
  const onCardNumberChange = (event) => {
    changeTextInput(event);
    const trimmedCardNumber = event?.target?.value.replaceAll(REGEX.FIND_ALL_WHITESPACE, '');
    if (trimmedCardNumber.length < 17 && !trimmedCardNumber.match(REGEX.ONLY_STRING_REGEX)) {
      const result = trimmedCardNumber.toString().replace(REGEX.CARD_NUMBER_SPACE_REGEX, '$& ');
      setValue('cardNumber', result);
      setCardNumberValue(result);
    }
  };

  const onCvcChange = (event) => {
    changeTextInput(event);
    if (event?.target?.value.length < 4) {
      let cvcNumberRegex = event?.target?.value.replace(/\D/g, '');
      setCvcNumber(cvcNumberRegex);
      setValue('cvc', cvcNumberRegex);
    }
  };

  const onChangeExpiration = (e) => {
    expriration(e?.target?.value);
    changeTextInput(e);
  };

  function validationMobileNumber(phoneNumber) {
    try {
      if (phoneNumber) {
        const number = phoneUtil.parse(phoneNumber, '');
        const isValid = phoneUtil.isValidNumber(number);
        if (!isValid) {
          setMobileNumberError({ countryError: true });
          return false;
        } else {
          setMobileNumberError({ countryError: false });
          return true;
        }
      } else {
        setMobileNumberError({ countryError: false });
        return false;
      }
    } catch (error) {
      setMobileNumberError({ countryError: false });
      return false;
    }
  }

  let allCountriesCode = [];
  dialCodes?.forEach((each) => {
    let dialCodeObj = {};
    dialCodeObj['name'] = each.name;
    dialCodeObj['dialCode'] = each.dialCode;
    dialCodeObj['code'] = each.code;
    dialCodeObj['priority'] = each.priority;
    dialCodeObj['displayString'] = each.name + ' +' + each.dialCode;
    allCountriesCode.push(dialCodeObj);
  });

  const validateMobilenumber = (phoneNumber) => {
    if (phoneNumber.length > 0) {
      let isMobileNumberValid = validationMobileNumber(phoneNumber);
      if (isMobileNumberValid) {
        setValue('mobileNumber', phoneNumber);
      }
    } else {
      if (phoneNumber !== '') {
        setMobileNumberError({ countryError: true });
      }
    }
  };

  let transactionDetails = {};
  const onSubmit = async (data) => {
    if (isBrowser) {
      window?.heap?.identify(newCompanyAllInfo?.email.trim());
    }
    try {
      if (newCompanyAllInfo?.phoneNumber !== null && !mobileNumberError.countryError) {
        if (paymentMethod === PAYMENT_METHOD?.CREDIT_CARD) {
          if (isValidMonthAndYear(data?.expiration)) {
            let payload = {
              email: newCompanyAllInfo?.email.trim(),
              cardNumber: parseInt(data?.cardNumber.replaceAll(REGEX.FIND_ALL_WHITESPACE, '')),
              cvc: data?.cvc
            };
            setUserAndCardDetails((userAndCardDetails) => ({
              ...userAndCardDetails,
              ...payload
            }));
            setLoading(true);
            const monthYear = data?.expiration.split('/');
            let checkoutPayload = {
              email: newCompanyAllInfo?.email.trim(),
              phoneNumber: newCompanyAllInfo?.phoneNumber,
              companyId,
              cardNumber: parseInt(data?.cardNumber.replaceAll(REGEX.FIND_ALL_WHITESPACE, '')),
              month: monthYear[0],
              year: monthYear[1],
              cvc: data?.cvc,
              description: 'company creation payment',
              firstName: newCompanyAllInfo?.firstName,
              lastName: newCompanyAllInfo?.lastName,
              registrationCountry: registrationCountry
            };
            sessionStorage.setItem(
              SESSION_STORAGE_NAME.NEW_COMPANY_ALL_INFO,
              JSON.stringify({
                ...newCompanyAllInfo,
                step: 6,
                expirationDate: expirationDate,
                cardNumberValue: cardNumberValue,
                cvcNumber: cvcNumber,
                companyId: companyId
              })
            );
            const firstThreeEntries = Object.entries(checkoutPayload).slice(0, 3);
            const payloadForSavingEmailAndPhoneNumber = Object.fromEntries(firstThreeEntries);
            const companyUpdateResponse = await companyUpdate(payloadForSavingEmailAndPhoneNumber);
            if (companyUpdateResponse?.status === API_CODE.STATUS_200) {
              await sendingEmailJs(
                checkoutPayload?.email.trim(),
                checkoutPayload?.phoneNumber,
                EMAIL_JS.NEW_HONGKONG_COMPANY_REGISTRATION
              );
              checkoutFunction(checkoutPayload);
            } else {
              setLoading(false);
              setPaymentModal(true);
            }
          } else {
            setCardsError({ ...cardsError, expiration: true });
          }
        }
        if (paymentMethod === PAYMENT_METHOD?.PAY_PAL) {
          setPayPalLoading(true);
          const companyUpdateResponse = await companyUpdate({
            companyId,
            transactionDetails: transactionDetails,
            isVerified: true,
            status: { statusTitle: 'Paid', bgColor: MUI_COLORS.GREEN, textColor: MUI_COLORS.WHITE }
          });
          if (companyUpdateResponse?.status === API_CODE.STATUS_200) {
            await sendingEmailJs(
              newCompanyAllInfo?.email.trim(),
              newCompanyAllInfo?.phoneNumber,
              EMAIL_JS.NEW_HONGKONG_COMPANY_REGISTRATION
            );
            await sendingEmailJs(
              newCompanyAllInfo?.email.trim(),
              newCompanyAllInfo?.phoneNumber,
              EMAIL_JS.COMPANY_TO_USER
            );
            navigate(
              `${EXTERNAL_LINKS.INVOICE_COMPANY_SETUP_URL}?companyId=${companyId}&email=${newCompanyAllInfo.email}`
            );
            sessionStorage.removeItem(SESSION_STORAGE_NAME.NEW_COMPANY_ALL_INFO);
          } else {
            setPayPalLoading(false);
          }
        }
      } else {
        setMobileNumberError({ countryError: true });
      }
    } catch (error) {
      setEmailJsResponse(error);
      setEmailJsErrorModal(true);
    }
  };

  const onSuccessfulPayPalPayment = (transactionInfo) => {
    transactionDetails = {
      paidWith: PAYMENT_METHOD?.PAY_PAL,
      date: transactionInfo?.create_time,
      id: transactionInfo.id,
      payerId: transactionInfo?.payer?.payer_id,
      payerEmailId: transactionInfo?.payer?.email_address,
      purchaseUnits: transactionInfo?.purchase_units
    };
    handleSubmit(onSubmit)();
  };

  async function checkoutFunction(payload) {
    const checkoutResponse = await checkout(payload);
    if (checkoutResponse?.status === API_CODE.STATUS_200) {
      if (checkoutResponse?.data?.statusCode === API_CODE.STATUS_200) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'Payment'
        });
        if (checkoutResponse?.data?.data?.validationRequire === true) {
          navigate(checkoutResponse?.data?.data?.results?.next_action?.redirect_to_url?.url);
        } else {
          navigate(
            `${EXTERNAL_LINKS.INVOICE_COMPANY_SETUP_URL}?companyId=${companyId}&email=${newCompanyAllInfo.email}`
          );
          sessionStorage.removeItem(SESSION_STORAGE_NAME.NEW_COMPANY_ALL_INFO);
        }
      } else if (
        checkoutResponse?.data?.apiStatus === CARD_ERROR?.CARD_DECLINED ||
        checkoutResponse?.data?.apiStatus === CARD_ERROR?.INCORRECT_NUMBER
      ) {
        setCardsError({ ...cardsError, cardNumber: true, message: checkoutResponse?.data?.message });
        setPaymentModal(true);
        setLoading(false);
      } else if (
        checkoutResponse?.data?.apiStatus === CARD_ERROR?.INVALID_EXPIRY_YEAR ||
        checkoutResponse?.data?.apiStatus === CARD_ERROR?.INVALID_EXPIRY_MONTH
      ) {
        setCardsError({ ...cardsError, expiration: true, message: checkoutResponse?.data?.message });
        setPaymentModal(true);

        setLoading(false);
      } else if (checkoutResponse?.data?.apiStatus === CARD_ERROR?.INVALID_CVC) {
        setCardsError({ ...cardsError, cvc: true, message: checkoutResponse?.data?.message });
        setPaymentModal(true);

        setLoading(false);
      } else {
        setCardsError({ ...cardsError, cardNumber: true, message: checkoutResponse?.data?.message });
        setPaymentModal(true);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }

  function templateParamsForMail(email, mobileNumber) {
    let templateParams = {
      companyName: newCompanyAllInfo?.companyName,
      companySecondaryName: newCompanyAllInfo?.companySecondaryName,
      industry: newCompanyAllInfo?.industry,
      email: email.trim(),
      firstName: newCompanyAllInfo?.firstName,
      lastName: newCompanyAllInfo?.lastName,
      paymentMethod: paymentMethod === PAYMENT_METHOD?.PAY_PAL ? 'PayPal' : 'Credit Card',
      phoneNumber: mobileNumber,
      SHName1: allStakeHolder[0]?.firstName,
      SHLastName1: allStakeHolder[0]?.lastName,
      SHNationality1: allStakeHolder[0]?.nationality?.name,
      SHAddress1: allStakeHolder[0]?.address,
      SHDirectOrNot1: allStakeHolder[0]?.isDirector,

      SHName2: allStakeHolder[1]?.firstName,
      SHLastName2: allStakeHolder[1]?.lastName,
      SHNationality2: allStakeHolder[1]?.nationality?.name,
      SHAddress2: allStakeHolder[1]?.address,
      SHDirectOrNot2: allStakeHolder[1]?.isDirector,

      SHName3: allStakeHolder[2]?.firstName,
      SHLastName3: allStakeHolder[2]?.lastName,
      SHNationality3: allStakeHolder[2]?.nationality?.name,
      SHAddress3: allStakeHolder[2]?.address,
      SHDirectOrNot3: allStakeHolder[2]?.isDirector,
      jurisdiction: newCompanyAllInfo?.registrationCountry,
      tokenLink: `${process.env.SITE_URL}${EXTERNAL_LINKS.INVOICE_COMPANY_SETUP_URL}?companyId=${companyId}&email=${newCompanyAllInfo.email}`
    };
    return templateParams;
  }

  const sendingEmailJs = async (email, mobileNumber, templateId) => {
    await send(
      EMAIL_JS.COMPANY_CREATION_SERVICE_ID,
      templateId,
      templateParamsForMail(email, mobileNumber),
      EMAIL_JS.PUBLIC_KEY
    ).then(
      function (response) {
        setEmailJsResponse(response);
      },
      function (error) {
        setEmailJsResponse(error);
        setEmailJsErrorModal(true);
      }
    );
  };
  const closeDynamicModal = () => {
    setEmailJsErrorModal(false);
  };

  const onPayPalClick = async () => {
    const result = true;
    return result;
  };
  function isValidEmail(email) {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  }
  useEffect(() => {
    isValidEmail(watch('email')) && setEmailError(false);
  }, [watch]);
  const tryAnotherCard = () => {
    setValue(`mobileNumber`, '');
    setValue(`cardNumber`, '');
    setValue(`expiration`, '');
    setValue(`cvc`, '');
    setPaymentMethod(PAYMENT_METHOD?.CREDIT_CARD);

    setPaymentModal(false);
  };
  // const tryPaypal = () => {
  //   setPaymentModal(false);
  //   setPaymentMethod(PAYMENT_METHOD?.PAY_PAL);
  // };
  return (
    <div>
      <div className="bg-white md:p-8 p-4 rounded-lg flex flex-col items-stretch justify-between company-registration-form-container">
        <StepsIndicator numberOfSteps={5} currentStepNumber={4} />

        <form id="payment-form" onSubmit={handleSubmit(onSubmit)}>
          <FormControl className="w-full">
            {loading ? (
              <div className="flex justify-center items-center payment-spinner-container">
                <DynamicModal hideCrossIcon={true} isCloseRequired={false} openDynamicModal={loading} minWidth="424px">
                  <div className="bg-white flex flex-col items-center justify-center loader-modal-height">
                    <StatrysLuckySpinner />
                    <XSText title="Processing" className="mt-4" textColor="text-gray-450" textAlign="text-center" />
                  </div>
                </DynamicModal>
              </div>
            ) : (
              <>
                <MDSubtitleText className="md:mt-10 mt-4" title="Payment" fontWeight="text-bold" />
                <div className="hidden bg-gray-200 rounded-lg border border-gray-200 mt-4 cursor-pointer">
                  <div
                    onClick={() => setPaymentMethod(PAYMENT_METHOD?.CREDIT_CARD)}
                    className={`px-6 py-4 flex items-center justify-center gap-2 rounded-lg w-full ${
                      paymentMethod === PAYMENT_METHOD?.CREDIT_CARD && 'bg-white'
                    } `}
                  >
                    <CreditCardInline stroke={MUI_COLORS?.BLACK} />
                    <MSText title="Credit Card" />
                  </div>
                  <div
                    onClick={() => setPaymentMethod(PAYMENT_METHOD?.PAY_PAL)}
                    className={`px-6 py-4 flex items-center justify-center gap-2 rounded-lg w-full ${
                      paymentMethod === PAYMENT_METHOD?.PAY_PAL && 'bg-white'
                    } `}
                  >
                    <PayPalInline />
                    <MSText title="Paypal" />{' '}
                  </div>{' '}
                </div>
                {paymentMethod === PAYMENT_METHOD?.PAY_PAL && (
                  <div>
                    <XSText className="md:whitespace-pre-line mt-4" title={`Contact information`} />
                    <div className="mt-2">
                      <TextInput
                        onKeyDown={handleEnter}
                        name="email"
                        value={newCompanyAllInfo?.email}
                        disabled
                        label="Email Address"
                        placeholder="e.g. name@domain.com"
                        variant="filled"
                        inputRef={register({
                          required: true,
                          pattern: {
                            value: emailRegex,
                            message: 'Please fill in a valid Email Address'
                          }
                        })}
                        error={errors.email ? true : false}
                        helperText={errors.email && <ValidationMessage title="Please fill in a valid Email Address" />}
                      />
                      {emailError && <ValidationMessage title="Please fill in a valid Email Address" />}
                      <div className="mt-4">
                        <MobileNumberAutoComplete
                          label="Phone Number"
                          placeholder="+85212312231212"
                          options={allCountriesCode}
                          value={newCompanyAllInfo?.phoneNumber}
                          disabled
                          name="mobileNumber"
                          onInputValueChange={validateMobilenumber}
                          inputRef={register({
                            required: true
                          })}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {paymentMethod === PAYMENT_METHOD?.CREDIT_CARD ? (
                  <div>
                    <XSText className="md:whitespace-pre-line mt-4" title={`Card information`} />
                    <div className="mt-2 flex felx-row relative">
                      <TextInput
                        defaultValue={
                          JSON.parse(sessionStorage.getItem(SESSION_STORAGE_NAME.NEW_COMPANY_ALL_INFO))?.cardNumberValue
                            ? JSON.parse(sessionStorage.getItem(SESSION_STORAGE_NAME.NEW_COMPANY_ALL_INFO))
                                ?.cardNumberValue
                            : userAndCardDetails?.cardNumber
                        }
                        name="cardNumber"
                        label="Card Number"
                        placeholder="1234 1234 1234 1234"
                        className="w-full"
                        max={19}
                        textFieldContainerClass="card-number-input"
                        onChange={(event) => onCardNumberChange(event)}
                        inputRef={register({
                          required: true
                        })}
                        error={errors.cardNumber || cardsError?.cardNumber ? true : false}
                        helperText={
                          (errors?.cardNumber || cardsError?.cardNumber) && (
                            <ValidationMessage title={cardsError?.message} />
                          )
                        }
                        autoComplete="cc-number"
                      />
                      <div className="card-image-container flex wrap ">
                        {cardTypes?.map((card, index) => {
                          return <img src={card?.image} alt={card?.alt} key={index} className="card-images" />;
                        })}
                      </div>
                    </div>
                    <div className="flex flex-row gap-4 mt-4">
                      <TextInput
                        defaultValue={
                          JSON.parse(sessionStorage.getItem(SESSION_STORAGE_NAME.NEW_COMPANY_ALL_INFO))?.expirationDate
                            ? JSON.parse(sessionStorage.getItem(SESSION_STORAGE_NAME.NEW_COMPANY_ALL_INFO))
                                ?.expirationDate
                            : ''
                        }
                        name="expiration"
                        label="Expiration"
                        placeholder="MM/YY"
                        onChange={(event) => onChangeExpiration(event)}
                        textFieldContainerClass="w-full"
                        inputRef={register({
                          required: true
                        })}
                        error={errors.expiration || cardsError?.expiration ? true : false}
                        helperText={
                          (errors?.expiration || cardsError?.expiration) && (
                            <ValidationMessage title={defaultValidationMessage('Expiration')} />
                          )
                        }
                      />
                      <TextInput
                        defaultValue={
                          JSON.parse(sessionStorage.getItem(SESSION_STORAGE_NAME.NEW_COMPANY_ALL_INFO))?.cvcNumber
                            ? JSON.parse(sessionStorage.getItem(SESSION_STORAGE_NAME.NEW_COMPANY_ALL_INFO))?.cvcNumber
                            : userAndCardDetails?.cvc
                        }
                        name="cvc"
                        label="CVC"
                        placeholder="123"
                        textFieldContainerClass="w-full"
                        max={3}
                        onChange={(event) => onCvcChange(event)}
                        inputRef={register({
                          required: true
                        })}
                        error={errors.cvc || cardsError?.cvc ? true : false}
                        helperText={
                          (errors?.cvc || cardsError?.cvc) && (
                            <ValidationMessage title={defaultValidationMessage('CVC')} />
                          )
                        }
                      />
                    </div>

                    <div className="mt-6 flex md:flex-row flex-col-reverse justify-between items-center">
                      <PrimaryButton
                        id="cc-paybutton"
                        caption={
                          registrationCountry === COMPANY_REGISTRATION_COUNTRY.HONG_KONG
                            ? `Pay HKD${FEES.COMPANY_CREATION_CAPTION}`
                            : `Pay SGD${FEES.SINGAPORE_COMPANY_CREATION_CAPTION}`
                        }
                        fontSize="text-base"
                        className="capitalize w-full my-2"
                        onClick={handleSubmit(onSubmit)}
                        isBorderRequired={true}
                        disabled={loading ? true : false}
                        linkClass="w-full"
                        bgColor={loading ? 'bg-gray-400' : 'bg-coral-500'}
                      />
                    </div>
                    <TermCondition />
                  </div>
                ) : (
                  <div>
                    <TermCondition />
                    <div className=" flex justify-between items-center mt-6 py-2">
                      <BackButton onClick={prevStep} />
                      {payPalLoading ? (
                        <StatrysLuckySpinner />
                      ) : (
                        <PaypalCheckoutButton
                          onPayPalClick={onPayPalClick}
                          onPayPalDecline={() => setPaymentModal(true)}
                          onSuccessfulPayPalPayment={onSuccessfulPayPalPayment}
                          registrationCountry={registrationCountry}
                        />
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </FormControl>
        </form>

        <DynamicModal openDynamicModal={emailJsErrorModal} closeDynamicModal={closeDynamicModal}>
          <MDSubtitleText fontWeight="text-bold" title="Oops! There has been an error" />
          <MSText className="my-6" title={`Error message: ${emailJsResponse?.text}`} />
        </DynamicModal>
        <DynamicModal openDynamicModal={paymentModal} closeDynamicModal={() => setPaymentModal(false)} minWidth="600px">
          <img src={CardErrorPNG} className="mx-auto w-44" alt="secure" height={97} width={80} />
          <MDSubtitleText
            fontWeight="text-bold"
            className="mt-6"
            textAlign="text-center"
            title="Your payment was not successful"
          />
          {cardsError?.message && <XSText className="my-6" textAlign="text-center" title={cardsError?.message} />}
          <XSText textAlign="text-center" title="To proceed, you may try using a different credit card" />
          <div className="flex gap-4 mt-6 items-center justify-center">
            <div
              className="md:w-1/2 w-full bg-coral-500 flex items-center gap-2 justify-center py-4 rounded-lg cursor-pointer"
              onClick={tryAnotherCard}
            >
              <CreditCardInline stroke={MUI_COLORS?.WHITE} />
              <XSText textAlign="text-center" textColor="text-white" title="Try another card" />
            </div>
            {/* <div
              className="w-11/12 bg-yellow-500 flex items-center gap-2 justify-center py-4 rounded-lg cursor-pointer"
              onClick={tryPaypal}
            >
              <PayPalInline />
              <XSText textAlign="text-center" textColor="text-gray-300" title="Pay with Paypal" />
            </div> */}
          </div>
        </DynamicModal>
        <div className="flex flex-row gap gap-6 mt-6 justify-center">
          <img src={Ssl} alt="ssl" className="secure-logo" />
          <img src={Stripe} alt="powered by stripe" className="secure-logo" />
          <img src={Aes} alt="aes" className="secure-logo" />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    companyId: state?.newAccountSetup?.companyCreateDetails?.data?._id
      ? state?.newAccountSetup?.companyCreateDetails?.data?._id
      : state?.newAccountSetup?.newCompanyRegistrationData?.companyId,
    dialCodes: state?.AccountSetupInfo?.countryDialCodeList?.data,
    allStakeHolder: state?.newAccountSetup?.allStakeHolder,
    newCompanyAllInfo: state?.newAccountSetup?.newCompanyRegistrationData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkout: (paymentPayload) => dispatch(actionsForRegistrationNewCompany.createPayment(paymentPayload)),
    countryDialCodes: () => dispatch(userActions.countryDialCodes()),
    companyUpdate: (companyUpdateData) => dispatch(actionsForRegistrationNewCompany.companyUpdate(companyUpdateData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentUI);

PaymentUI.propTypes = {
  checkout: PropTypes.func,
  countryDialCodes: PropTypes.func,
  companyId: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
  name: PropTypes.string,
  bgColor: PropTypes.string,
  dialCodes: PropTypes.array,
  companyUpdate: PropTypes.func,
  allStakeHolder: PropTypes.array,
  newCompanyAllInfo: PropTypes.object,
  prevStep: PropTypes.func,
  registrationCountry: PropTypes.string
};
