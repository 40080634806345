/**
 * SEO component that queries for data with
 * Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

function Seo({ description, title, children, unFollow, noIndex }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            image
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = title || site.siteMetadata?.title;

  return (
    <>
      <Helmet
        title={defaultTitle ? defaultTitle : 'Statrys'}
        meta={[
          {
            name: `title`,
            content: `${title}`
          },
          {
            name: `description`,
            content: metaDescription
          },
          {
            name: `twitter:title`,
            content: `${title}`
          },
          {
            name: `twitter:description`,
            content: metaDescription
          },

          {
            name: `twitter:card`,
            content: `summary_large_image`
          },
          {
            name: `twitter:site`,
            content: `@Statrys`
          },
          {
            name: `twitter:creator`,
            content: `Statrys`
          },
          {
            property: `og:title`,
            content: `${title}`
          },
          {
            property: `og:description`,
            content: metaDescription
          },

          unFollow || noIndex
            ? {
                name: `robots`,
                content: `noindex,nofollow`
              }
            : {},
          unFollow || noIndex
            ? {
                name: `googlebot`,
                content: `noindex,nofollow`
              }
            : {}
        ]}
      >
        {children}
      </Helmet>
    </>
  );
}

export default Seo;
