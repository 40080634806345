export const API_CODE = {
  STATUS_200: 200,
  STATUS_201: 201,
  STATUS_204: 204
};

export const FILE_EXTENTION_TYPE = {
  PDF: 'pdf',
  JPEG: 'jpeg',
  DOCX: 'docx',
  PNG: 'png',
  DOC: 'doc',
  JPG: 'jpg'
};

export const MUI_COLORS = {
  CORAL: '#E15741',
  WHITE: '#FFFFFF',
  SILVER_MEDAL: '#D6D6D6',
  SUNSET_ORANGE: '#FF4F42',
  EXTRA_LIGHT_GRAY: '#F9F9F9',
  PURPLE: '#2D0D45',
  DARK_CHARCOAL: '#2F2F2F',
  DARK_GRAY: '#474747',
  GRAY: '#7A7A7A',
  LIGHT_CREAM: '#F9DBD1',
  LIGHT_GREEN: '#E6F2F1',
  LIGHT_YELLOW: '#FFEDDE',
  LIGHT_GRAY: '#F5F1ED',
  DARK_GRAY_MAIN: '#464646',
  GREEN: '#097D75',
  SUNGLOW: '#FFC730',
  BLUE: '#1B6BD3',
  WHATS_APP_GREEN: '#25D365',
  PINK: '#FCEFE8',
  PAPAYA_WHIP: '#FFF3D4',
  DARK_GREEN: '#043734',
  MID_NIGHT: '#212B36',
  DARK_GRAYISH_BLUE: '#919EAB',
  CORAL_PINK: '#F6D0CA',
  GRAY_FOR_DISABLE_INPUTS: '#FAFAFA',
  DISABLE: '#d3d3d3'
};
export const AVATAR_COlORS = ['#2D0D45', '#097D75', '#043734', '#1B6BD3', '#FFC730', '#F9DBD1', '#DAD2BC', '#E2FBFA'];

export const STATUS = {
  FALSE: 'false',
  TRUE: 'true'
};

export const DateFormats = {
  DDMMMYYY: 'DD MMM YYYY'
};

export const ERROR_STATUS_CODE = {
  ONB_1038: 'ONB-1038',
  ONB_1008: 'ONB-1008'
};

export const FILE_SIZE = {
  FOUR_MB_IN_BYTES: 4194304
};

export const STRING_MAX_CHAR = {
  NINE: 9,
  TEN: 10,
  TWENTY: 20,
  TWENTY_TWO: 22,
  TWENTY_THREE: 23,
  TWENTY_EIGHT: 28,
  TWENTY_FIVE: 25,
  TWENTY_NINE: 29,
  THIRTY_FIVE: 35
};

export const PASSWORD_TYPE = {
  password: 'password',
  confirmPassword: 'confirmPassword'
};

export const EXTERNAL_LINKS = {
  APPLE_STORE: 'https://apps.apple.com/us/app/statrys/id1590487745',
  GOOGLE_STORE: 'https://play.google.com/store/apps/details?id=com.statrys.statrys',
  TRUST_PILOT: 'https://www.trustpilot.com/review/statrys.com',
  SENDGRID_CONTACTS: 'https://api.sendgrid.com/v3/marketing/contacts',
  COMPANY_CREATION_CALENDAR: ' https://calendly.com/incorporation/discovery-company-creation-call',
  HONG_KONG_CALENDAR: 'https://calendly.com/incorporation/onboarding-call-company-creation',
  SINGAPORE_CALENDAR: 'https://calendly.com/incorporation/onboarding-call-company-creation-singapore',

  INVOICE: 'https://invoice.statrys.com/signup',
  INVOICE_COMPANY_SETUP_URL: '/company-set-up',
  GUEST_BLOG: 'https://notionforms.io/forms/write-for-us-1',
  AIRWALLEX_REVIEW: 'https://notionforms.io/forms/airwallex-review',
  SINGAPORE_COMPANY_CREATION_CALENDAR: 'https://calendly.com/incorporation/discovery-call-company-creation-singapore',
  OCEAN_YOUTUBE_SHORT: 'https://www.youtube.com/embed/rHYkJRSu_pA?autoplay=1',
  BUSINESS_ACCOUNT_YOUTUBE_VIDEO: 'https://www.youtube.com/watch?v=qDKRpHcw20I',
  Talk_TO_US: 'https://calendly.com/statrys/discovery-call'
};

export const CHAR_CODE = {
  ENTER_KEY_CODE: 13
};

export const INVOICE_TYPE = {
  FROM: 'From',
  TO: 'To'
};

export const SVG_TYPES = {
  DASHBOARD: 'DASHBOARD',
  MULTIPLEUSER: 'MULTIPLEUSER',
  ACCOUNTINVOICE: 'ACCOUNTINVOICE',
  CASHPAYMENT: 'CASHPAYMENT',
  PROFILE: 'PROFILE',
  SETTING: 'SETTING',
  LOGOUT: 'LOGOUT',
  ADD_CIRCLE: 'ADD_CIRCLE',

  STATRYS_LOGO: 'statrysLogo',
  BOOK_OPEN_BOOKMARK: 'bookOpenBookMark',
  EURO_IBAN_ACCOUNT: 'euroIbanAccount',
  CREDIT_CARD: 'creditCard',
  CURRENCY_EURO_DOLLAR_EXCHANGE: 'currencyEuroDollarExchange',
  TAG_DOLLAR: 'tagDollar',
  QUESTION_CIRCLE: 'questionCircle',
  BOOK_LIBRARY: 'bookLibrary',
  BOOK_OPEN: 'bookOpen',
  SINGLE_MAN_MAIL: 'singleManMail',
  TEAM_IDEA: 'teamIdea',
  NEWS_PAPER_READ: 'newsPaperRead',
  BUSINESS_PARTNER: 'businessPartner',
  CONTACT_US: 'contactUs',
  BULB: 'bulb',
  RATING_STARS: 'ratingStars',
  SEND_EMAIL: 'sendEmail',
  CONTACT_PHONE: 'contactPhone',
  PERFORMANCE_GRAPH: 'calculator',
  ACCOUNTING_CALCULATOR: 'stripeCalculator',
  INVOICE_GENERATOR: 'Invoice',
  PAYMENTS_BILLS: 'PAYMENTS_BILLS',
  GET_REWARDED: 'GET_REWARDED',
  WRITE_BLOG: 'WRITE_BLOG'
};
export const INVOICE_FONTS = {
  HKGROTESK_BOLD: `${process.env.SITE_URL}/static/HKGrotesk-Bold-c164da0c8b51b1b3fbdc0d251c7eccd4.woff2`,
  HKGROTESK_REGULAR: `${process.env.SITE_URL}/static/HKGrotesk-Regular-24f8897e48415679153df49efb95f20a.woff2`
};

export const PAGE_TITLE = {
  DASHBOARD: 'Dashboard',
  GET_PAID: 'Get paid',
  CREATE_INVOICE: 'Create Invoice',
  INVOICE_LIST: 'Invoices',
  EDIT_INVOICE: 'Edit Invoice',
  VIEW_INVOICE: 'View Invoice',
  ACCOUNT_SETTINGS: 'Account Settings',
  PROFILE: 'Profile',
  CONTACT: 'Contact',
  VIEW_CONTACT: 'View Contact',
  VIEW_INCOMING_INVOICE: 'View Incoming Invoices',
  IMPORTING_INVOICE: 'Importing invoices',
  EDIT_IMPORTING_INVOICE: 'Edit Importing Invoices',
  SETTINGS: 'Settings',
  PAY: 'Pay',
  COMPANY: 'Company',
  COMPANY_PROFILE: 'Company profile',
  COMPANY_ORGANIZATION: 'Organization',
  COMPANY_MAILROOM: 'Mailroom',
  COMPANY_DOCUMENTS: 'Documents'
};

export const CONTACT_TABLE_TITLE = {
  ALL: 'All',
  ACTIVE: 'Active',
  DELETED: 'Deleted',
  ONLY_CLIENTS: 'Client',
  ONLY_SUPPLIERS: 'Supplier'
};

export const INVOICE_STATUS = {
  INVOICE_TOTAL: 'All',
  INVOICE_PAID: 'Paid',
  INVOICE_UNPAID: 'Unpaid',
  INVOICE_OVERDUE: 'Overdue',
  INVOICE_DRAFT: 'Draft',
  UPLOADED: 'Uploaded',
  AWAITING_VALIDATION: 'Awaiting',
  PARTIALLY_PAID: 'Partially paid',
  VALID: 'Valid',
  INVALID: 'Invalid',
  NONE: 'None',
  VALIDATION: 'Validation',
  INVOICE_PARTIAL_PAID: 'Partial Paid',
  INVOICE_REVIEW: 'Review'
};

export const COMPANY_STATUS = {
  COMPANY_SECRETARY: 'Company Secretary',
  MAIL: 'Mail',
  SHAREHOLDER: 'Shareholder',
  DIRECTOR: 'Director',
  CORPORATE_SHAREHOLDER: 'Corporate shareholder'
};

export const PAYMENT_STATUS_INVOICE = {
  PAID: 'paid',
  PARTIAL_PAID: 'partialpaid',
  UNPAID: 'unpaid'
};

export const CONTACT_INFO = {
  WHATSAPP_LINK: 'https://wa.me/85264523564',
  TELEPHONE_LINK: 'tel:+852 5803 2818',
  SUPPORT_EMAIL: 'mailto:incorporation@statrys.com'
};

export const PREFERED_DUE_DATE_DURATION = [{ name: 30 }, { name: 60 }, { name: 90 }];

export const INVOICE_STATUS_SELECT = [
  { name: INVOICE_STATUS.INVOICE_DRAFT },
  { name: INVOICE_STATUS.INVOICE_UNPAID },
  { name: INVOICE_STATUS.INVOICE_PAID }
];

export const DENSE_HEIGHT_SIZE = {
  NORMAL: '56',
  MEDIUM: '76'
};

export const ROW_PER_PAGE_OPTIONS = {
  FIVE: '5',
  TEN: '10',
  TWENTY_FIVE: '25',
  FIFTY: '50'
};

export const REQUIRED_FILE_SIZE = {
  ONE_MB: 1024
};

export const TYPE_OF_OTP = {
  SIGN_UP: 1,
  FORGOT_PASSWORD: 2,
  RESEND_CODE: 3
};

export const TYPE_OF_INVOICE = {
  INVOICE: 1,
  IMPORTING_INVOICE: 2
};

export const STATUS_COLORS = {
  STATRYS_CORAL: MUI_COLORS.CORAL,
  STATRYS_YELLOW: MUI_COLORS.SUNGLOW,
  LIGHT_GREEN: MUI_COLORS.LIGHT_GREEN,
  GREEN: MUI_COLORS.GREEN,
  STATRYS_RED: MUI_COLORS.SUNSET_ORANGE,
  LIGHT_RED: MUI_COLORS.LIGHT_CREAM,
  STATRYS_GREY: '#637381',
  BLUE: MUI_COLORS.BLUE,
  LIGHT_BLUE: '#E6EFFA',
  GREY: '#D9D9D9',
  BACKGROUND_GREY: '#F9F9F9',
  CORAL_PINK: MUI_COLORS.CORAL_PINK,
  MALVA: '#F1E6FD'
};

export const EMITTER_CONSTANTS = {
  INPUT_FROM_MAIN: 'INPUT_FROM_MAIN'
};

export const API_ERROR_MESSAGES = {
  DEFAULT: 'Something went wrong'
};

export const SOCIAL_SHARE_LINK = {
  FACEBOOK_URL: 'https://www.facebook.com/sharer/sharer.php?u=',
  TELEGRAM_URL: 'https://t.me/share/url?url=',
  WHATSAPP_URL: 'https://api.whatsapp.com/send?text='
};

export const CONFIDENCE_TYPE = {
  HIGH: 1,
  LOW: 0.7,
  VERY_LOW: 0.5
};
export const SETTING_TABS_LABEL = {
  PROFILE: 'Profile',
  SECURITY: 'Security',
  PREFERENCES: 'Preferences',
  COMPANY_DETAILS: 'Company details',
  PLANS_LIMITS: 'Plans and limits',
  LEGAL_DOCUMENTS: 'Legal documents',
  COMPANY_SETTINGS: 'Company Settings'
};

export const COMPANY_TABS = {
  PROFILE: 'Profile',
  ORGANIZATION: 'Organization',
  MAILROOM: 'Mailroom',
  COMPANY_SECRETARY: 'Company Secretary',
  REQUESTS: 'Requests'
};

export const EXTERNAL_PAYMENT_LINKS = {
  PAYPAL_LINK: 'https://paypal.me/',
  PAYME_HSBC_LINK: 'https://payme.hsbc/'
};

export const ENVIRONMENT_VARIABLES = {
  QA: 'qa',
  PRODUCTION: 'production',
  DEVELOPMENT: 'development'
};

export const LOGIN_TAB = {
  BUSINESS_ACCOUNT: 'BUSINESS_ACCOUNT',
  INVOICE: 'INVOICE',
  COMPANY_MANAGEMENT: 'COMPANY_MANAGEMENT'
};

export const COMPANY_SETUP_STEP = {
  START: 1,
  EMAIL: 2,
  VERIFY_OTP: 3,
  START_COMPANY_INFORMATION: 4,
  COMPANY_NAME: 5,
  INDUSTRY: 6,
  BUSINESS_DESCRIPTION: 7,
  OWNERS_AND_DIRECTOR_SELECTOR: 8,
  PASSPORT_UPLOAD: 9,
  PERSONAL_INFORMATION: 10,
  RESIDENTIAL_ADDRESS_INFORMATION: 11,
  BUSINESS_AND_EXPERIENCE_DETAILS: 12,
  OWNER_AND_DIRECTOR_LIST: 13,
  CAPITAL_OF_THE_COMPANY: 14,
  SIGN: 15,
  STEP_COMPLETE_SUCCESS: 16
};
export const CONSTANT_NUMBER = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  THIRTEEN: 13,
  FOURTEEN: 14
};

export const COMPANY_SETUP_SIDEBAR_STEP = {
  COMPANY_INFORMATION: 1,
  OWNERS_AND_DIRECTORS: 2,
  SIGN: 3
};

export const COMPANY_SETUP_SIDEBAR_SUB_STEP = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3
};

export const COMPANY_DESCRIPTION_STEP = {
  DESCRIPTION: 1,
  COUNTRIES: 2,
  TURNOVER: 3
};

export const MAX_LENGTH = {
  FIRST_NAME: 3,
  LAST_NAME: 2
};
export const STORAGE_NAME = {
  IS_EXISTED_USER: 'isExistedUser'
};

export const OWNER_DIRECTOR_TYPES = {
  OWNER: 'OWNER',
  DIRECTOR: 'DIRECTOR',
  OWNER_DIRECTOR: 'OWNER_DIRECTOR',

  NOMINEE_OWNER: 'NOMINEE_OWNER',
  NOMINEE_OWNER_DIRECTOR: 'NOMINEE_OWNER_DIRECTOR'
};

export const EXPERIENCE_VALUE = {
  ZERO: '0',
  ONE: '1',
  TWO_TO_FIVE: '2 to 5',
  FIVE_TO_TEN: '5 to 10',
  TEN_PLUS: '10+'
};

export const BUSINESS_EXPERIENCE = {
  BUSINESS_OWNER: 'Business owner',
  EMPLOYEE: 'Employee',
  CONSULTANT: 'Consultant',
  FREELANCER: 'Freelancer'
};

export const REGISTERED_COUNTRY = {
  HONGKONG: 'HongKong',
  SINGAPORE: 'Singapore'
};

export const OFFICE_ADDRESS = {
  HONG_KONG_ADDRESS: 'Unit B, 11/F, 23 Thomson Road, Wan Chai, Hong Kong SAR China',
  SINGAPORE_ADDRESS: '7 Temasek Boulevard, #12-07 Suntec Tower One, Singapore 038987'
};

export const API_ERROR_CODES = {
  STATUS_400: 400,
  STATUS_401: 401,
  STATUS_402: 402,
  STATUS_403: 403,
  STATUS_404: 404,
  STATUS_412: 412,
  STATUS_422: 422,
  STATUS_500: 500,
  STATUS_409: 409,
  STATUS_405: 405,
  STATUS_413: 413
};
export const INDUSTRIES_CODE = {
  SUB_CATEGORY: 'OT-OT'
};
export const APPLICANT_TYPE = {
  THIRD_PARTY: 'thirdParty',
  SOLE_OWNER: 'soleOwner',
  MULTIPLE_OWNER: 'multipleOwner'
};
export const RELATIONSHIP_WITH_COMPANY = {
  OPTIONS: ['Employee', 'Company Secretary', 'Accountant', 'Others'],
  COMPANY_SEC: 'COMPANY_SEC',
  COMPANY_SECRETARY: 'Company Secretary',
  EMPLOYEE: 'Employee',
  ACCOUNTANT: 'Accountant',
  OTHERS: 'Others'
};
export const REGEX = {
  UPPER_CASE_REGEX: /(?=.*[A-Z])/,
  LOWER_CASE_REGEX: /(?=.*[a-z])/,
  DIGIT_CASE_REGEX: /(?=.*\d)/,
  SPECIAL_CASE_REGEX: /(?=.*[-+_!@#$%^&*.,?])/,
  SPACE_REGEX: /(?=.*\s)/,
  EMAIL_REGEX: /^(?=[\w\s-+.])\s*[-+.'\w]+@[-.\w]+\.[-.\w]+\s*$/,
  HUBSPOT_COOKIE_REGEX: '(^|;)\\s*hubspotutk\\s*=\\s*([^;]+)',
  ONLY_STRING_REGEX: /([A-Za-z])$/,
  REPLACE_STRING_REGEX: /[^\d.-]/g,
  REPLACE_SLASH: /\//g,
  EXCEPT_SLASH_AND_QUOTES_REGEX: /^[^/\\'"]+$/,
  FIND_ALL_WHITESPACE: /\s/g,
  CARD_NUMBER_SPACE_REGEX: /\d{4}(?=.)/g,
  WATCH: /watch\?v=/,
  ONLY_ALPHABETS_AND_SPACE_ALLOWED: /^[A-Za-z\s]*$/,

  TAGS_NOT_ALLOWED: /^((?!<>|<|>).)*$/s,
  NEW_LINE: /(?:\r\n|\r|\n)/g,
  NUMBER_CHECKER_REGEX: /^[0-9]+$/,
  GET_NUMBER_WITH_DECIMAL: /[^0-9\.-]+/g,
  GET_VALUE_WITHOUT_DECIMAL_INT: /[0-9\.]/g,
  IS_GREATER_THAN_ZERO: /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/,
  PASSPORT_NUMBER: /[a-zA-Z]{2}[0-9]{7}/,
  NAME_WITH_ACCENTED_CHARACTERS: /^[A-Za-zÀ-ÿÇç ]+$/
};
export const VALIDATE_TOKEN = {
  TYPE: 'SU'
};
export const JURISDICTION = {
  HK: { code: 'HK', name: 'Hong Kong SAR' },
  SG: { code: 'SG', name: 'Singapore' },
  VG: { code: 'VG', name: 'British Virgin Islands' },
  OTHER_JURISDICTION: 'other-jurisdiction'
};
export const COMPANY_REGISTRATION_COUNTRY = {
  HONG_KONG: 'HongKong',
  SINGAPORE: 'Singapore'
};
export const VERIFY_OTP_STATUS = {
  VERIFIED: 'VERIFIED'
};
export const COUNTRY_CODE = {
  ALPHA_2: 'alpha-2'
};
export const COMPANY_STRUCTURE_CARD_TYPE = {
  INDIVIDUAL: 'Individual',
  COMPANY: 'Company',
  THIRD_PARTY: 'ThirdParty'
};
export const COMPANY_STRUCTURE_EDIT_TYPE = {
  EDIT_OWNER_AND_DIRECTOR: 'editOwnerAndDirector',
  EDIT_THIRD_PARTY: 'editThirdParty'
};

export const FILE_UPLOAD_TYPE = {
  PDF: 'application/pdf',
  PNG: 'image/png',
  JPEG: 'image/jpeg',
  JPG: 'image/jpg',
  MSWORD: 'application/msword',
  OFFICE_DOCUMENT: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  URL: 'url'
};
export const DOCUMENT_UPLOAD_TYPE = {
  BUSINESS_PROFILE: 'BUSINESS_PROFILE',
  PASSPORT: 'PASSPORT',
  PASSPORT_ID: 'PASSPORT_ID'
};

export const DOCUMENT_SOURCE_TYPE = {
  PROSPECT_CLIENT: 'PROSPECT_CLIENT',
  PROSPECT_ONBOARDING_OTHERS_INTERNAL_UPLOAD: 'PROSPECT_ONBOARDING_OTHERS_INTERNAL_UPLOAD',
  PROSPECT_ONBOARDING_KYB_INTERNAL_UPLOAD: 'PROSPECT_ONBOARDING_KYB_INTERNAL_UPLOAD',
  CLIENT_KYB_OTHERS_INTERNAL_UPLOAD: 'CLIENT_KYB_OTHERS_INTERNAL_UPLOAD'
};
export const STAKEHOLDER_TYPES = {
  INDIVIDUAL: 'INDIVIDUAL',
  COMPANY: 'COMPANY'
};
export const COMPANY_TYPE = {
  REGISTERED: 'REGISTERED'
};
export const APPLICATION_STAGE_COMPLETE = {
  COMPANY_HIERARCHY: 'COMPANY_HIERARCHY',
  DOCUMENT_UPLOAD: 'DOCUMENT_UPLOAD'
};
export const APPLICATION_SUBMISSION_META_QUESTIONS = {
  SOURCE_OF_DISCOVERY: 'SOURCE_OF_DISCOVERY',
  AUTHORIZE_STATRYS: 'AUTHORIZE_STATRYS',
  ACKNOWLEDGE_PROHIBITED_COUNTRIES: 'ACKNOWLEDGE_PROHIBITED_COUNTRIES',
  TERMS_AND_CONDITIONS_AGREEMENT: 'TERMS_AND_CONDITIONS_AGREEMENT'
};

export const HEAR_ABOUT_STATRYS = {
  AS0: { code: 'AS0', text: 'Found you online' },
  AS1: { code: 'AS1', text: 'Recommended by a friend' },
  AS2: { code: 'AS2', text: 'Recommended by a partner' },
  AS3: { code: 'AS3', text: 'I was in touch with your team' },
  AS4: { code: 'AS4', text: 'I have another account with Statrys' },
  AS5: { code: 'AS5', text: 'Recommended by my Company Secretary' }
};

export const APPLICATION_COMPLETION_PERCENTAGE = {
  COMPLETION_0: 0,
  COMPLETION_20: 20,
  COMPLETION_40: 40,
  COMPLETION_60: 60,
  COMPLETION_80: 80
};

export const RESOURCE_TYPE = {
  WHITEPAPER: 'Whitepapers',
  NEWSLETTER: 'Newsletters'
};

export const REQUIRED_LENGTH = {
  OTP_LENGTH: 6,
  PASSWORD_LENGTH: 10,
  ALLOWED_DOCUMENT_LENGTH: 100,
  DESCRITPTION_LENGTH: 49,
  REFERRAL_CODE_LENGTH: 6
};

export const COMPANY_STRUCTURE_STEP_CASE = {
  RELATIONSHIP_WITH_COMPANY_STEP: 'RelationShipWithCompanyStep'
};

export const MFA_OTP_STATUS = {
  VERIFIED: 'VERIFIED'
};

export const BUSINESS_PROFILE_DATA = {
  INDUSTRY: 'Industry',
  SUB_INDUSTRY: 'Sub-industry'
};

export const MFA_NOTIFICATION_STATUS = {
  PENDING_ACTION: 'PENDING_ACTION',
  CONFIRMED: 'CONFIRMED',
  REJECTED: 'REJECTED',
  EXPIRED: 'EXPIRED'
};

export const MFA_CHANNEL_TYPES = {
  PUSH_NOTIFICATION: 'PUSH_NOTIFICATION'
};

export const CLIENT_APPLICATION_COUNT = {
  TWO: 2,
  ONE: 1,
  ZERO: 0
};
export const VERIFY_MOBILE_NUMBER_STATUS = {
  INCORRECT: 'INCORRECT'
};

export const RICH_TEXT_TYPE = {
  PURPLE: 'purple',
  GREEN: 'green',
  YELLOW: 'yellow',
  GRAY: 'gray',
  PINK: 'pink',
  SPACER: 'spacer'
};

export const SETUP_PASSWORD_TOKEN_VALIDATE_STATE = {
  VALID: 'VALID',
  INVALID: 'INVALID',
  ALREADY_ACCOUNT_EXISTS: 'ALREADY_ACCOUNT_EXISTS'
};

export const AUTH_CODES = {
  AUTH_1037: 'AUTH-1037'
};

export const BLOGS_UID = {
  MAIN_BLOG: 'opening-a-business-bank-account-in-hong-kong',
  FEATURED_BLOG: 'setting-up-a-company'
};

export const SHOW_NUMBER_OF_BLOGS = {
  TWELVE: 12,
  SIX: 6,
  EIGHTEEN: 18,
  TEN: 10,
  FIFTEEN: 15,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  NINE: 9,
  EIGHT: 8
};

export const NOT_SHOW_BORDER_OF_BLOG = {
  EIGHT: 8,
  FOUR: 4,
  NINE: 9,
  TWELVE: 12,
  FIVE: 5
};
export const INTERNAL_LINKS = {
  TERMS_AND_CONDITIONS: `${process.env.SITEURL}/terms-and-conditions`,
  STRIPE_SUCCESS_URL: `${process.env.SITEURL}/signup/success`,
  STRIPE_CANCEL_URL: `${process.env.SITEURL}/signup/failure`,
  PRIVACY_POLICY: `${process.env.SITEURL}/terms-and-conditions/privacy-policy`
};
export const EMAIL_JS = {
  PUBLIC_KEY: 'Ndc-RofFsjZvXX_2O',
  SERVICE_ID: 'service_blnhz0m',
  COMPANY_CREATION_SERVICE_ID: 'noreply_new',
  LEAVE_REVIEW_TEMPLATE_ID: 'template_z2qwwil',
  REGISTER_COMPANY_WAITING_LIST: 'template_l8atqd5',
  HONGKONG_COMPANY_REGISTRATION: 'template_nnfi22d',
  NEW_HONGKONG_COMPANY_REGISTRATION: 'company_registration_new',
  COMPANY_TO_USER: 'company_to_user',

  INVOICING: 'invoicing',
  EMAIL_SUPPORT: 'email-support'
};

export const SLICE_TYPE = {
  RICH_TEXT: 'rich_text',
  IMAGE: 'image',
  TABLE: 'table',
  PODCAST: 'podcast',
  YOUTUBE_VIDEO: 'youtube_video',
  EDITORS_NOTE: 'editors_note',
  BLOG_CTA: 'blog_cta',
  KEY_TAKEAWAYS: 'key_takeaways',
  DOUBLE_COLUMN: 'double_column',
  QUOTE: 'quote',
  REPORT_SLICE: '_report_slice',
  BLOG_IMAGE_WITH_LINK: 'blog_image_with_link',
  REPORT_KEY_POINTS: 'report_key_points',
  LINK_CTA: 'link_cta',
  HIGHLIGHT_BOX: 'highlight_box'
};
export const BLOG_CATEGORIES_TYPE = {
  BANKING_FUNDAMENTALS: 'Banking Fundamentals',
  NEWS: 'News',
  COMPETITOR_REVIEWS: 'Competitor reviews'
};

export const UNSPLASH_REFERRAL = {
  WEBSITE_URL: 'https://unsplash.com',
  URL: '?utm_source=Statrys&utm_medium=referral'
};
export const MODAL_TYPE = {
  PROHIBITED_INDUSTRIES: 'PROHIBITED-INDUSTRIES',
  RESTRICTED_COUNTRIES: 'RESTRICTED-COUNTRIES'
};

export const MODAL_TITLE = {
  PROHIBITED_INDUSTRIES: 'Prohibited Industries',
  RESTRICTED_COUNTRIES: 'Restricted Countries'
};
export const SPECIAL_CHARACTER_VALIDATION = {
  FOR_NAMES: /[%<>\\$#!@%^&*()?+'"]/,
  FOR_SPACE: /\s/g,
  FOR_NBSP: /(&nbsp;)*/g,
  FOR_COMMA: /[,.]/g
};

export const REGISTER_COMPANY_WAITING_LIST_STEP = {
  FINAL_STEP: 4
};

export const VERIFY_MFA_OTP_WARNING_MESSAGES = {
  REACTIVATE_WARNING_MESSAGE:
    'To re-activate push notifications for MFA on your mobile app, please login on your phone again and follow the steps.',
  ENABLE_MFA_NOTIFICATION_WARNING_MESSAGE:
    'To enable push notifications for MFA on your mobile app, please login on your phone and follow the steps.',
  LOGIN_THROUGH_APP_WARNING_MESSAGE:
    'Please login on your mobile app and follow the steps in order to continue receiving MFA notifications from the mobile app. '
};

export const MFA_WARNING_TYPES = {
  VALIDATION_KEY_EXPIRED: 'VALIDATION_KEY_EXPIRED'
};

export const PRODUCT_PAGES_URL = {
  COMPANY_SECRETARY_URL: '/reviews/company-secretary-hong-kong',
  PRIVACY_POLICY_URL: '/terms-and-conditions/privacy-policy',
  HONGKONG_COMPANY_REGISTRATION: '/hong-kong-company-registration'
};

export const BLOG_UID = {
  PAYPAL_BUSINESS_VS_PERSONAL: 'paypal-business-vs-personal',
  PAYPAL_PAYMENT_GUIDE: 'paypal-payment-guide',
  WITHDRAW_MONEY_PAYPAL: 'withdraw-money-paypal',
  ADD_MONEY_PAYPAL: 'add-money-paypal'
};

export const FEES = process.env.COMPANY_CREATION_DISCOUNT
  ? {
      FOR_PAY_PAL: 7740,
      SINGAPORE_FOR_PAY_PAL: 3150,
      COMPANY_CREATION: 774000,
      COMPANY_CREATION_CAPTION: '7,740',
      SINGAPORE_COMPANY_CREATION_CAPTION: '3,150'
    }
  : {
      FOR_PAY_PAL: 8600,
      SINGAPORE_FOR_PAY_PAL: 3500,
      COMPANY_CREATION: 860000,
      COMPANY_CREATION_CAPTION: '8,600',
      SINGAPORE_COMPANY_CREATION_CAPTION: '3,500'
    };
export const HONG_HONG_COMPANY_CREATION_ORIGINAL_PRICING = [
  {
    title: 'HKD',
    price: '8,600'
  },
  {
    title: 'EUR',
    price: '1,025'
  },
  {
    title: 'USD',
    price: '1,099'
  }
];
export const SINGAPORE_COMPANY_CREATION_ORIGINAL_PRICING = [
  {
    title: 'SGD',
    price: '3,500'
  },
  {
    title: 'EUR',
    price: '2,390'
  },
  {
    title: 'USD',
    price: '2,630'
  }
];
export const HONG_KONG_COMPANY_CREATION_FEES_DROPDOWN = process.env.COMPANY_CREATION_DISCOUNT
  ? [
      {
        title: 'HKD',
        price: '7,740'
      },
      {
        title: 'EUR',
        price: '922'
      },
      {
        title: 'USD',
        price: '996'
      }
    ]
  : HONG_HONG_COMPANY_CREATION_ORIGINAL_PRICING;

export const SINGAPORE_COMPANY_CREATION_FEES_DROPDOWN = process.env.COMPANY_CREATION_DISCOUNT
  ? [
      {
        title: 'SGD',
        price: '3,150'
      },
      {
        title: 'EUR',
        price: '2,151'
      },
      {
        title: 'USD',
        price: '2,343'
      }
    ]
  : SINGAPORE_COMPANY_CREATION_ORIGINAL_PRICING;

export const CARD_ERROR = {
  INVALID_EXPIRY_YEAR: 'invalid_expiry_year',
  INVALID_EXPIRY_MONTH: 'invalid_expiry_month',
  CARD_DECLINED: 'card_declined',
  INVALID_CVC: 'incorrect_cvc',
  INCORRECT_NUMBER: 'incorrect_number'
};
export const QUIZE_STEPS = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10
};
export const ACCEPTABEL_INDUSTRIES_CODE_FOR_OTHERJURISDICTION = [
  'WTT-PT',
  'LGT-LG',
  'LGT-WH',
  'LGT-TP',
  'LGT-OT',
  'PMO-VOIP'
];
// Need to correct
export const MAX_LENGTH_N = {
  COMPANY_NAME: 100,
  EMAIL: 100,
  FIRST_NAME: 50,
  LAST_NAME: 50
};

export const TERMS_AND_CONDITIONS_UID = {
  TERMS_AND_CONDITIONS: 'terms-and-conditions',
  LIST_PROHIBITED_INDUSTRIES: 'list-prohibited-industries',
  PRIVACY_POLICY: 'privacy-policy',
  LIST_PROHIBITED_COUNTRIES: 'list-prohibited-countries'
};

export const OTHER_COMPANY_REGISTRATION_NAME = {
  SLEEK: 'Sleek',
  OSOME: 'Osome',
  STARTUPREGISTRY: 'StartUpRegistry'
};

export const SIGN_UP_FLOW_CONSTANT = {
  GET_STARTED: 'getStarted'
};

export const SENDGRID_DETAILS = {
  LIST_ID_PAYPAL_CALCULATOR_SIGNUP: '4cff10ad-80a6-40cc-ba03-53c4af866881',
  LIST_IDS: '9a63f38f-6b80-4b13-8d99-e72d748171d8',
  TOKEN: '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'
};
export const SESSION_STORAGE_NAME = {
  COUNTRY_FOR_BUSINESS_ACCOUNT_REVIEW: 'countryForBusinessAccountReview',
  ALL_COMPANIES: 'allCompanies',
  NEW_COMPANY_ALL_INFO: 'newCompanyAllInfo',
  IS_ANY_COMPANY_CLICKED: 'isAnyCompanyClicked',
  USERNAME: 'userName',
  COMPANY_ID: 'companyId'
};

export const POST_TYPE = {
  ARTICLE: 'article',
  BLOG: 'blog',
  SILOS_SECTION: 'silos-section'
};

export const PAYMENT_METHOD = {
  CREDIT_CARD: 'CREDIT_CARD',
  PAY_PAL: 'PAY_PAL'
};

export const COMPANY_CREATE_STEPS = {
  REVIEW: 5,
  ACCOUNTING: 3
};

export const MOBILE_DEVICE = {
  MOBILE_WIDTH: 767
};

export const RENEWAL_STATUS = {
  ON: 'ON',
  OFF: 'OFF',
  MANUAL: 'MANUAL'
};
