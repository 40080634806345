import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';
import MSText from '../ui/typography/MSText';
import Avatar from '../ui/Avatar';
import { stringTruncate } from '../../helper/helpers';
import { SESSION_STORAGE_NAME, STRING_MAX_CHAR, SVG_TYPES } from '../../constants/enum';
import { Divider } from '@mui/material';
import '../../styles/headerNavigation.scss';
import Badge from '../companyList/Badge';
import Tick from '../../images/icons/check-box-tick.svg';
import { connect } from 'react-redux';
import * as CompanyAction from '../../components/companyIncorporation/reduxStore/action';
import { API_CODE } from '../../constants/enum';
import { PATH_PAGE } from '../../routes/paths';
import XMSText from '../ui/typography/XMSText';
import { getSvgIcon } from '../../helper/svgHelper';
import { getUser } from '../authService/auth';
import { RENEWAL_STATUS } from '../../constants/enum';
import checkBox from '../../images/icons/check-box-tick.svg';

function NavigationDropDown({
  menuItems,
  needSeparator,
  separtorPositions,
  children,
  companyList,
  isNavigation,
  toSwitchCompany
}) {
  const [onHover, setOnHover] = useState();

  const switchCompany = async (companyDetails) => {
    if (companyDetails?.isApproved) {
      const payload = {
        email: companyDetails?.email,
        companyId: companyDetails?._id
      };
      sessionStorage.setItem(SESSION_STORAGE_NAME?.IS_ANY_COMPANY_CLICKED, true);
      const response = await toSwitchCompany(payload);
      if (response?.status === API_CODE.STATUS_200) {
        sessionStorage.setItem('token', response.data?.data?.token);
        sessionStorage.setItem('companyId', response.data?.data?.companyDetails?._id);
        navigate('/');
      }
    } else if (companyDetails?.finishedApplication === true && companyDetails?.isApproved === false) {
      sessionStorage.setItem('companyId', companyDetails?._id);
      navigate(`${PATH_PAGE.companyIncorporationSuccess}?continue-form=true`);
    } else if (companyDetails?.finishedApplication === false) {
      sessionStorage.setItem('companyId', companyDetails?._id);
      navigate(`${PATH_PAGE.companyIncorporation}?continue-form=true`);
    }
  };

  const handleNewCompanyCreation = () => {
    navigate(`${PATH_PAGE.signUp}/start`);
    sessionStorage.setItem('companyId', '');
  };

  const isAnyOngoingCompany = companyList?.find((company) => company?.finishedApplication === false);

  const handleCompanyExpiring = (companyDetails) => {
    navigate(PATH_PAGE?.renewPayment, {
      state: {
        companyDetails: companyDetails
      }
    });
  };
  return (
    <>
      <div className={`${isNavigation ? 'dropdown-navigation-container' : 'dropdown-container'} -ml-10`}>
        <div className="dropdown-content">
          <div className="tooltip-container">
            <div className="tooltip-icon"></div>
          </div>
          <div
            className="dropdown-content-container max-h-[600px] overflow-scroll flex flex-col"
            onMouseLeave={() => setOnHover()}
          >
            {children}
            {companyList && (
              <>
                <Divider sx={{ marginTop: '16px' }} />
                <div className="p-2">
                  {companyList
                    ?.filter(
                      (companyDetails) =>
                        companyDetails?.finishedApplication === true &&
                        companyDetails?.isApproved === true &&
                        companyDetails?.renewalStatus != RENEWAL_STATUS.MANUAL
                    )
                    ?.map((company) => {
                      return (
                        <div
                          className="flex flex-row gap-2 items-center cursor-pointer px-2 py-2 hover:bg-gray-100 rounded-lg"
                          onClick={() => switchCompany(company)}
                        >
                          <div className="rounded-full">
                            <Avatar
                              avatarStyling="w-10 h-10"
                              avatarImage={company?.logo}
                              avatarName={company?.companyName && company?.companyName?.charAt(0)}
                            />
                          </div>
                          <div className="flex flex-col w-full gap-1">
                            <div className="flex flex-row items-start justify-between w-full gap-2">
                              <MSText
                                title={stringTruncate(company?.companyName, STRING_MAX_CHAR.TWENTY_NINE)}
                                fontWeight="text-bold"
                              />
                              {company?._id == sessionStorage.getItem('companyId') &&
                                (sessionStorage.getItem(SESSION_STORAGE_NAME?.IS_ANY_COMPANY_CLICKED) ||
                                  companyList.length === 1) && <img src={Tick} width="16" height="16" />}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {companyList
                    ?.filter(
                      (companyDetails) =>
                        companyDetails?.finishedApplication === true && companyDetails?.isApproved !== true
                    )
                    ?.map((company) => {
                      return (
                        <div
                          className="flex flex-row gap-2 items-center cursor-pointer px-2 py-2 hover:bg-gray-100 rounded-lg"
                          onClick={() => switchCompany(company)}
                        >
                          <div className="rounded-full">
                            <Avatar
                              avatarStyling="w-10 h-10"
                              avatarImage={company?.logo}
                              avatarName={company?.companyName && company?.companyName?.charAt(0)}
                            />
                          </div>
                          <div className="flex flex-col w-full gap-1">
                            <div className="flex flex-row items-start justify-between w-full gap-2">
                              <MSText
                                title={stringTruncate(company?.companyName, STRING_MAX_CHAR.TWENTY_NINE)}
                                fontWeight="text-bold"
                              />
                            </div>
                            {company?.finishedApplication === true && company?.isApproved === false && (
                              <Badge color="green-200" title="Under Review" />
                            )}
                            {company?.finishedApplication === false && (
                              <Badge color="salmon-500" title="Ongoing Application" />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  {companyList
                    ?.filter((companyDetails) => companyDetails?.finishedApplication === false)
                    ?.map((company) => {
                      return (
                        <div
                          className="flex flex-row gap-2 items-center cursor-pointer px-2 py-2 hover:bg-gray-100 rounded-lg"
                          onClick={() => switchCompany(company)}
                        >
                          <div className="rounded-full">
                            <Avatar
                              avatarStyling="w-10 h-10"
                              avatarImage={company?.logo}
                              avatarName={company?.companyName && company?.companyName?.charAt(0)}
                            />
                          </div>
                          <div className="flex flex-col w-full gap-1">
                            <div className="flex flex-row items-start justify-between w-full gap-2">
                              <MSText
                                title={stringTruncate(company?.companyName, STRING_MAX_CHAR.TWENTY_NINE)}
                                fontWeight="text-bold"
                              />
                            </div>
                            {company?.finishedApplication === false && (
                              <Badge color="salmon-500" title="Ongoing Application" />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  {companyList
                    ?.filter(
                      (companyDetails) =>
                        companyDetails?.renewalStatus === RENEWAL_STATUS?.MANUAL && companyDetails?.isApproved === true
                    )
                    ?.map((company) => {
                      return (
                        <>
                          <div
                            className="flex flex-row gap-2 items-center cursor-pointer px-2 py-2 hover:bg-gray-100 rounded-lg"
                            onClick={() => {
                              if (!JSON?.parse(sessionStorage?.getItem('closeRenewalModalClicked'))) {
                                handleCompanyExpiring(company);
                              } else {
                                switchCompany(company);
                              }
                            }}
                          >
                            <div className="rounded-full">
                              <Avatar
                                avatarStyling="w-10 h-10"
                                avatarImage={company?.logo}
                                avatarName={company?.companyName && company?.companyName?.charAt(0)}
                              />
                            </div>
                            <div className="flex flex-col w-full gap-1">
                              <div className="flex flex-row items-start justify-between w-full gap-2">
                                <MSText
                                  title={stringTruncate(company?.companyName, STRING_MAX_CHAR.TWENTY_NINE)}
                                  fontWeight="text-bold"
                                />
                              </div>
                              <Badge color="yellow-500" title="License Expiring Soon" />
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </>
            )}

            <div
              className={`flex flex-row items-center rounded-lg px-4 py-2 mx-2 ${!onHover && 'hover:bg-gray-100'} `}
              onClick={() => handleNewCompanyCreation()}
            >
              <div>{getSvgIcon(SVG_TYPES.ADD_CIRCLE)}</div>
              <MSText title="Register another company" textColor="text-blue-300" className="dropDown-email-text ml-2" />
            </div>
            <div className="navigation-dropdown-bottom-line my-2"></div>

            <div className="mb-2">
              {menuItems.map((menu, index) => {
                return (
                  <>
                    {needSeparator && separtorPositions.includes(index) ? (
                      <div className="navigation-dropdown-bottom-line my-2"></div>
                    ) : null}
                    {menu.onMenuItemClick ? (
                      <div onClick={menu.onMenuItemClick} className="hover:no-underline cursor-pointer ">
                        <div
                          onMouseEnter={() => setOnHover(index)}
                          onMouseLeave={() => setOnHover()}
                          className={`flex flex-row items-center rounded-lg px-4 py-2 mx-2 ${
                            index === onHover ? 'bg-gray-100 ' : ''
                          }`}
                          key={index}
                        >
                          <div>{menu?.icon}</div>
                          <MSText
                            title={menu?.pageName}
                            textColor="text-blue-300"
                            className="dropDown-email-text ml-2"
                          />

                          {menu?.isNewMenuItem && (
                            <div className="bg-coral-500 rounded-lg ml-2">
                              <XMSText title="New" textColor="text-white" className="px-2 py-1" />
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <Link to={menu?.to} className="hover:no-underline cursor-pointer ">
                        <div
                          onMouseEnter={() => setOnHover(index)}
                          onMouseLeave={() => setOnHover()}
                          className={`flex flex-row items-center rounded-lg px-4 py-2 mx-2 ${
                            index === onHover ? 'bg-gray-100 ' : ''
                          }`}
                          key={index}
                        >
                          <div>{menu?.icon}</div>
                          <MSText
                            title={menu?.pageName}
                            textColor="text-blue-300"
                            className="dropDown-email-text ml-2"
                          />

                          {menu?.isNewMenuItem && (
                            <div className="bg-coral-500 rounded-lg ml-2">
                              <XMSText title="New" textColor="text-white" className="px-2 py-1" />
                            </div>
                          )}
                        </div>
                      </Link>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    toSwitchCompany: (companyPayload) => dispatch(CompanyAction.switchCompany(companyPayload))
  };
};
export default connect(null, mapDispatchToProps)(NavigationDropDown);

NavigationDropDown.propTypes = {
  menuItems: PropTypes.array,
  needSeparator: PropTypes.bool,
  separtorPositions: PropTypes.array,
  children: PropTypes.any
};
