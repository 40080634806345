export const isBrowser = () => typeof window !== 'undefined';

export const isVWOLoaded = () => {
  return typeof window !== 'undefined' && window._vwo && typeof window._vwo !== 'undefined';
};

// Calculate the period covered
export function getPeriodCovered(startDateStr) {
  const startDate = new Date(startDateStr);
  const endDate = new Date(startDate);
  endDate.setDate(endDate.getDate() + 365);

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  return `${formattedEndDate}`;
}

// Format the date to dd-mm-yyyy
export function formatDate(dateStr) {
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}
