import React, { useEffect, useState, useCallback, useLayoutEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import OutlineDownArrow from '../../images/icons/outlineDownArrow.svg';
import FooterLogo from '../../images/icons/footer-statrys-logo.svg';
import StatrysSupport from '../common/statrysSupport/StatrysSupport';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { navigate } from 'gatsby';
import { MOBILE_DEVICE, MUI_COLORS, SESSION_STORAGE_NAME, STRING_MAX_CHAR, SVG_TYPES } from '../../constants/enum';
import { PATH_PAGE, IMPORTING_INVOICE } from '../../routes/paths';
import { getSecondLastPathName, getURLFirstPathName, stringTruncate } from '../../helper/helpers';
import MSText from '../ui/typography/MSText';
import { useLocation } from '@reach/router';
import { getUser, isLoggedIn, logOut } from '../../components/authService/auth';
import { connect, useSelector } from 'react-redux';
import * as userProfileActions from '../../components/profile/reduxStore/action';
import * as CompanyAction from '../../components/companyIncorporation/reduxStore/action';
import { getSvgIcon } from '../../helper/svgHelper';
import BaseText from '../ui/typography/BaseText';
import NavigationDropDown from '../Navigation/NavigationDropDown';
import XSText from '../ui/typography/XSText';
import CompanyManagementIcon from '../../images/icons/header/company-management.svg';
import Buildings from '../../images/icons/sideBar/buildings.svg';
import Hierarchy from '../../images/icons/sideBar/hierarchy.svg';
import Mailbox from '../../images/icons/sideBar/mailbox.svg';
import BookLibrary from '../../images/icons/sideBar/book-library.svg';
import ActiveBuildings from '../../images/icons/sideBar/buildings-green.svg';
import ActiveHierarchy from '../../images/icons/sideBar/hierarchy-green.svg';
import ActiveMailbox from '../../images/icons/sideBar/mailbox-green.svg';
import ActiveBookLibrary from '../../images/icons/sideBar/book-library-green.svg';
import Avatar from '../ui/Avatar';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Banner from '../../components/common/Banner';

const drawerWidth = 270;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const companyNavigation = [
  {
    id: 1,
    pageName: 'Company Profile',
    icon: Buildings,
    activeIcon: ActiveBuildings,
    to: PATH_PAGE.companyProfile
  },
  {
    id: 2,
    pageName: 'Organization',
    icon: Hierarchy,
    activeIcon: ActiveHierarchy,
    to: PATH_PAGE.companyOrganization
  },
  {
    id: 3,
    pageName: 'Mailroom',
    icon: Mailbox,
    activeIcon: ActiveMailbox,
    to: PATH_PAGE.companyMailRoom
  },
  {
    id: 4,
    pageName: 'Documents',
    icon: BookLibrary,
    activeIcon: ActiveBookLibrary,
    to: PATH_PAGE.companyDocuments
  }
];

const profileOptions = [
  {
    pageName: 'Profile',
    icon: getSvgIcon(SVG_TYPES.PROFILE),
    to: PATH_PAGE.companyProfileSetting,
    id: 2
  },
  {
    pageName: 'Logout',
    icon: getSvgIcon(SVG_TYPES.LOGOUT),
    onMenuItemClick: logOut,
    id: 4
  }
];

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

function NavigationBar({
  children,
  getProfile,
  getUserProfile,
  getCompanyDetails,
  noSideBar,
  hideBanner,
  isRenewalSuccessPage
}) {
  const { width } = useWindowDimensions();
  const [open, setOpen] = useState(true);
  const [profileData, setProfileData] = useState({});
  const user = getUser();
  const location = useLocation();
  const [allCompanies, setAllCompanies] = useState([]);
  const [companyDetails, setCompanyDetails] = useState({});
  const [bannerVisible, setIsBannerVisible] = useState(false);
  const companyAllInformation = useSelector(
    (state) => state?.companyIncorporationReducer?.getCompanyIncorporation?.data?.data || {}
  );

  const navigationMenu = companyNavigation;

  const handleClick = (value) => {
    navigate(value);
  };

  const handleActiveTab = (urlLink) => {
    const urlFirstPathName = getURLFirstPathName(location?.pathname);
    const linkFirstPath = getURLFirstPathName(urlLink);
    if (`/${urlFirstPathName}` === PATH_PAGE.company) {
      const urlSecondPathName = getSecondLastPathName(location?.pathname);
      const linkSecondPath = getSecondLastPathName(urlLink);
      if (urlSecondPathName === linkSecondPath) {
        return true;
      } else {
        return false;
      }
    } else {
      if (urlFirstPathName === linkFirstPath) {
        return true;
      } else if (
        (`/${urlFirstPathName}` === PATH_PAGE.viewIncomingInvoices || `/${urlFirstPathName}` === IMPORTING_INVOICE) &&
        urlLink === PATH_PAGE.pay
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    getUserProfileInfo();
    getCompanyDetailsHandler();
  }, [isRenewalSuccessPage]);

  useLayoutEffect(() => {
    const bannerState = sessionStorage.getItem('isBannerHidden');
    setIsBannerVisible(null);
  }, []);

  const getCompanyDetailsHandler = useCallback(async () => {
    const companyId = sessionStorage.getItem('companyId');
    if (companyId) {
      const loggedInCompanyDetails = await getCompanyDetails(companyId);
      setCompanyDetails(loggedInCompanyDetails?.data?.data);
    }
    const allCompanies = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_NAME?.ALL_COMPANIES));
    setAllCompanies(allCompanies?.companies);
  }, [allCompanies]);

  const getUserProfileInfo = async () => {
    await getUserProfile();
  };

  useEffect(() => {
    setProfileData(getProfile);
  }, [getProfile]);
  const firstName = profileData?.firstName ? profileData?.firstName : companyDetails?.stakeholder?.[0]?.firstName;
  const lastName = profileData?.firstName ? profileData?.lastName : companyDetails?.stakeholder?.[0]?.lastName;
  const fullName = firstName + ' ' + lastName;
  const avatarName = fullName || companyAllInformation?.companyName;

  const navBannerMarginHandler = () => {
    if (hideBanner || !bannerVisible) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <Box id="main-container" sx={{ display: 'flex' }} className="max-w-screen-2xl mx-auto">
        <AppBar
          open={open}
          position="fixed"
          sx={{
            marginBottom: navBannerMarginHandler() && '56px',
            backgroundColor: 'white',
            boxShadow: 'none',
            '& .MuiToolbar-root': {
              paddingRight: width < MOBILE_DEVICE.MOBILE_WIDTH ? '16px' : '40PX',
              paddingLeft: width < MOBILE_DEVICE.MOBILE_WIDTH ? '16px' : '40PX',
              minHeight: '66px',
              maxHeight: '66px'
            }
          }}
        >
          {!hideBanner && <Banner setIsBannerVisible={setIsBannerVisible} />}

          <Toolbar className="max-w-screen-2xl w-full mx-auto">
            <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">
              <div className="flex justify-start gap-2 items-center">
                <img src={CompanyManagementIcon} alt="company management" />
                <div>
                  <BaseText
                    fontSize="text-xl"
                    className="md:whitespace-normal whitespace-pre-line"
                    title={`Company \nManagement`}
                  />
                </div>
              </div>
            </Typography>
            <div className="flex gap-8">
              <div className="flex items-center item-menu-dropdown dropdown">
                <div className="flex-row gap-2 items-center cursor-pointer py-2 md:flex hidden">
                  <div>
                    {companyAllInformation?.companyName && (
                      <MSText
                        title={stringTruncate(
                          sessionStorage.getItem(SESSION_STORAGE_NAME?.IS_ANY_COMPANY_CLICKED) ||
                            allCompanies?.length === 1
                            ? companyAllInformation?.companyName
                            : fullName,
                          STRING_MAX_CHAR.TWENTY_NINE
                        )}
                        fontWeight="text-bold"
                      />
                    )}
                    <MSText
                      title={stringTruncate(user?.email, STRING_MAX_CHAR.TWENTY_NINE)}
                      className="menu-item-text"
                      textColor="text-gray-450"
                    />
                  </div>
                  <div>
                    <img src={OutlineDownArrow} alt={OutlineDownArrow} />
                  </div>
                </div>
                <Avatar
                  avatarStyling="w-10 h-10 md:hidden block"
                  fontSize="text-base"
                  avatarName={avatarName?.charAt(0)}
                />
                <NavigationDropDown
                  menuItems={profileOptions}
                  isNavigation={true}
                  companyList={allCompanies}
                  needSeparator={true}
                  separtorPositions={[1]}
                >
                  <div className="mt-4 px-4">
                    <XSText
                      title={
                        profileData?.lastName && profileData?.firstName
                          ? stringTruncate(fullName, STRING_MAX_CHAR.THIRTY_FIVE)
                          : ''
                      }
                      fontWeight="text-bold"
                      lineHeight="leading-6"
                    />
                    <MSText
                      title={user?.email && stringTruncate(user?.email, STRING_MAX_CHAR.THIRTY_FIVE)}
                      textColor="text-gray-450"
                      className="dropDown-email-text whitespace-nowrap overflow-hidden"
                    />
                  </div>
                </NavigationDropDown>
              </div>
              {/* <div className="flex items-center item-menu-dropdown dropdown">
              <div className="flex flex-row gap-2 items-center cursor-pointer">
                <img src={HeaderMenuIcon} alt="header menu" />
              </div>
              <AccountSwitcherMenu menuItems={profileOptions} />
            </div> */}
            </div>
          </Toolbar>
        </AppBar>
        {!noSideBar && (
          <Drawer
            variant="permanent"
            open={open}
            sx={{
              '& .MuiDrawer-paper': {
                position: 'relative',
                zIndex: '100',
                backgroundColor: MUI_COLORS.LIGHT_GRAY,
                borderRight: 'none',
                paddingLeft: '32px',
                paddingRight: '32px',
                marginTop: navBannerMarginHandler() && '56px',
                height: navBannerMarginHandler() ? '90%' : '100%'
              }
            }}
          >
            <div className="flex flex-col justify-between h-full mt-20">
              <div>
                <List>
                  {navigationMenu.map((navigate) => (
                    <ListItem key={navigate.id} disablePadding sx={{ display: 'block', marginBottom: '4px' }}>
                      <ListItemButton
                        sx={{
                          minHeight: 56,
                          justifyContent: 'center',
                          px: 2.5,
                          '&:hover': { backgroundColor: 'white' }
                        }}
                        className={`${handleActiveTab(navigate?.to) && 'bg-white'} rounded-lg`}
                        onClick={() => handleClick(navigate?.to)}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center'
                          }}
                          className={`${handleActiveTab(navigate?.to) && 'bg-white'} rounded-lg`}
                          onClick={() => handleClick(navigate?.to)}
                        >
                          <img
                            className={`${handleActiveTab(navigate?.to) && 'text-green-500'}`}
                            src={handleActiveTab(navigate?.to) ? navigate?.activeIcon : navigate?.icon}
                            alt={navigate?.pageName}
                          />
                        </ListItemIcon>
                        <ListItemText
                          className={`${handleActiveTab(navigate?.to) && 'text-gray-500 font-bold'}`}
                          sx={{
                            opacity: open ? 1 : 0,
                            '& .MuiTypography-root': {
                              fontWeight: handleActiveTab(navigate?.to) && 700
                            }
                          }}
                        >
                          {navigate.pageName}
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </div>

              <footer className="pb-5">
                <div className="flex justify-center">
                  <img src={FooterLogo} alt={FooterLogo} />
                </div>
                <StatrysSupport />
              </footer>
            </div>
          </Drawer>
        )}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            backgroundColor: MUI_COLORS.LIGHT_GRAY,
            height: '90vh',
            marginTop: navBannerMarginHandler() && '56px'
          }}
        >
          <div className="layout-container pt-4 mx-auto min-h-full grid place-items-center bg-customBg-secondaryBg pb-8">
            <main
              className={`${isLoggedIn ? 'py-14' : 'py-0'} h-full w-full flex ${
                noSideBar ? 'items-center' : 'items-start'
              } justify-center ${navBannerMarginHandler() && 'md:py-14 pt-32'} `}
            >
              {children}
            </main>
          </div>
        </Box>
      </Box>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getProfile: state?.userProfieReducer?.getUserProfileDetails?.data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: () => dispatch(userProfileActions.getUserProfile()),
    getCompanyDetails: (companyId) => dispatch(CompanyAction.getCompanyIncorporation(companyId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
