import React, { useEffect } from 'react';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import BaseText from '../ui/typography/BaseText';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import AlertTriangle from '../../images/icons/renewPayment/alert-triangle-yellow.svg';
import GreenCheck from '../../images/icons/green-tick.svg';
import XSText from '../ui/typography/XSText';
import '../../styles/pages/renew-payment/renew-payment.scss';
import { CONSTANT_NUMBER } from '../../constants/enum';
import { PATH_PAGE } from '../../routes/paths';
import CloseIcon from '../../images/icons/close-icon.svg';
import { FEES, COMPANY_REGISTRATION_COUNTRY } from '../../constants/enum';
import { formatDate, getPeriodCovered } from '../../../utils/utils';

const PackageDetails = ({ setStep, companyDetails }) => {
  const packageHiglights = [
    {
      point: 'Full Business Registration Certificate \nrenewal (including government fees)'
    },
    {
      point: 'Filing of Annual Return (NAR1)'
    },
    {
      point: 'Company Secretary services'
    },
    {
      point: 'Registered address'
    },
    {
      point: 'Scanning and forwarding of mail'
    }
  ];

  const closeButtonClicked = () => {
    navigate(PATH_PAGE?.companiesList);
    sessionStorage?.setItem('closeRenewalModalClicked', true);
  };

  const brExpiryDate = companyDetails?.brExpiryDate;
  const registrationCountry = companyDetails?.registrationCountry;
  const renewalAmount =
    registrationCountry === COMPANY_REGISTRATION_COUNTRY.HONG_KONG
      ? FEES.COMPANY_CREATION_CAPTION
      : FEES.SINGAPORE_COMPANY_CREATION_CAPTION;
  const currencyTitle = registrationCountry === COMPANY_REGISTRATION_COUNTRY.HONG_KONG ? 'HKD' : 'SGD';

  const expiryDateInDDMMYYYY = brExpiryDate && formatDate(brExpiryDate);
  const periodCovered = brExpiryDate && getPeriodCovered(brExpiryDate);
  return (
    <div className="bg-customBg-secondaryBg sm:mt-5 lg:mt-0">
      <div className="bg-white box-shadow rounded-2xl shadow-lg mb-8 md:p-8 p-4 md:w-[960px] max-w-[90vw]">
        <div className="w-full flex flex-col items-between">
          <div className="flex justify-between">
            <div className="flex items-center gap-4">
              <img className="w-12 h-12" src={AlertTriangle} alt="Alert triangle img" />
              <BaseText
                className="whitespace-pre-line"
                fontSize="md:text-3xl text-lg"
                title={`Keep your Hong Kong company in good standing!`}
                fontWeight="text-bold md:text-normal"
              />
            </div>
            <div onClick={() => closeButtonClicked()} className="cursor-pointer">
              <img src={CloseIcon} className="w-4 h-4" alt="close icon" />
            </div>
          </div>
          <div className="flex flex-col gap-4 mt-8 mb-4 text-black text-base leading-[120%] tracking-[-0.26px]">
            <p className="text-base leading-[120%] tracking-[-0.26px] text-black">
              {' '}
              The Business Registration Certificate <span className="text-bold">("BR") </span> for your company,{' '}
              <span className="text-bold"> {companyDetails?.companyName}</span>, is set to expire on{' '}
              <span>{expiryDateInDDMMYYYY}</span>.
            </p>
            <p className="text-base leading-[120%] tracking-[-0.26px] text-black">
              Keeping your company compliant with Hong Kong regulations requires renewing the BR before the expiry date.
            </p>
          </div>

          <div className="flex md:flex-row flex-col gap-2 mb-4">
            <div className="md:w-3/5 w-full flex flex-col gap-2">
              <div className="p-4 border border-green-200 flex flex-col gap-2 rounded-2xl">
                <XSText
                  fontWeight="text-bold text-black text-base leading-[120%] tracking-[-0.26px]"
                  title="What happens if you don’t renew the BR?"
                />
                <div className="package-details-points flex flex-col gap-2">
                  <ul className="text-black text-base leading-[110%]">
                    <li className="validation-points text-base">
                      Operating a business in Hong Kong without a valid Business Registration Certificate (BRC) is
                      illegal.
                    </li>
                    <li className="validation-points text-base">Your company may incur a fine of up to HKD 5,000.</li>
                    <li className="validation-points text-base">
                      Your company’s bank account could be closed due to non-compliance with local regulations.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="p-4 border border-green-200 flex flex-col gap-2 rounded-2xl">
                <XSText
                  fontWeight="text-bold text-black text-base leading-[120%] tracking-[-0.26px]"
                  title="How to renew the BR?"
                />
                <div className="package-details-points flex flex-col gap-2">
                  <ul className="text-black text-base leading-[110%]">
                    <li className="validation-points text-base">
                      Simply instruct us to proceed with the renewal of your BR.
                    </li>
                    <li className="validation-points text-base">
                      We will manage the entire administrative process with the relevant Hong Kong authorities on your
                      behalf.
                    </li>
                    <li className="validation-points text-base">
                      The renewal process typically takes 10 working days, and your BR will be extended until{' '}
                      {periodCovered}.
                    </li>
                    <li className="validation-points text-base">
                      The renewal fee is HKD 7,740 (inclusive of government fees).
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="p-4 border border-green-200 flex flex-col gap-2 rounded-2xl">
                <XSText fontWeight="text-bold" title="What is the Renewal Fee?" />
                <div className="package-details-points flex flex-col gap-2">
                  <ul>
                    <li className="validation-points text-base">
                      Annual Renewal Fee: <span className="text-bold">{renewalAmount}</span>
                    </li>
                    <li className="validation-points text-base">
                      Period Covered: <span className="text-bold">{periodCovered}</span>
                    </li>
                  </ul>
                </div>
             </div> 
            */}
            </div>
            <div className="md:w-2/5 w-full whitespace-pre-line rounded-2xl bg-green-200 p-6">
              <div className="bg-green-200 p-4 rounded-2xl flex flex-col gap-4">
                <BaseText
                  fontSize="text-lg"
                  fontWeight="text-bold"
                  className="mb-4"
                  textAlign="text-center"
                  title="One Package, All Inclusive."
                />
                {packageHiglights?.map((highlightPoint, index) => (
                  <div className="flex gap-2" key={index + 1}>
                    <img className="w-4 h-4" src={GreenCheck} alt="Green Check Mark" />
                    <XSText
                      className={'text-gray-500 text-base leading-[18px] tracking-[-0.5px]'}
                      title={highlightPoint?.point}
                    />
                  </div>
                ))}
                <div className="mt-4 flex flex-col items-center justify-start">
                  <div>
                    <div className="flex flex-row">
                      <p className="hkd-package text-green-500">{currencyTitle}</p>
                      <p className="business-account-price-package text-green-500">{renewalAmount}</p>
                    </div>
                    <BaseText
                      className="flex justify-end"
                      fontSize="text-[11px]"
                      title={`(equiv. of USD975)`}
                      fontWeight="text-medium"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:justify-center md:items-center gap-3.5 mt-4 md:mt-0 w-full">
            <div className="flex flex-row justify-center gap-2">
              <button
                className="px-6 h-[50px] flex justify-center items-center bg-white text-green-500 text-base tracking-[0.5px] leading-[100%] border border-green-500 rounded-lg"
                onClick={() => closeButtonClicked()}
              >
                I’ll do it later
              </button>
              <button
                className="px-6 h-[50px] flex justify-center items-center bg-green-500 text-white text-base tracking-[0.5px] leading-[100%] border border-green-500 rounded-lg"
                onClick={() => setStep(CONSTANT_NUMBER.ONE)}
              >
                Renew Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PackageDetails.propTypes = {
  setStep: PropTypes.func,
  companyDetails: PropTypes.object
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(null, mapDispatchToProps)(PackageDetails);
