import { COMPANY_REGISTRATION_COUNTRY } from '../constants/enum';

export const ENDPOINTS = {
  COMPANY_REGISTRATION: '/signup/start',
  HONG_KONG_COMPANY_REGISTRATION: `/signup/start?country=${COMPANY_REGISTRATION_COUNTRY.HONG_KONG}`,
  SINGAPORE_COMPANY_REGISTRATION: `/signup/start?country=${COMPANY_REGISTRATION_COUNTRY.SINGAPORE}`,
  HONG_KONG_LANDING_PAGE: '/hong-kong-company-registration',
  SINGAPORE_LANDING_PAGE: '/singapore-company-registration',
  OPEN_ACCOUNT: '/signup/getstarted',
  BUSINESS_OPEN_ACCOUNT: '/signup/signupStart?=jurisdiction',
  INTEGRATION_XERO: '/integration/xero',
  SELECT_MANAGER: '/signup/selectManager',
  PRICING: '/pricing',
  INVOICES: '/invoicing-software',
  INVOICES_GENERATOR: '/tools/invoice-generator',
  BUSINESS_ACCOUNT: '/business-account',
  INTERNATIONAL_AND_LOCAL_PAYMENTS: '/international-and-local-payments',
  PAYMENT_CARD: '/uk/payment-card',
  FOREX: '/forex',
  XERO: '/integration/xero',
  BUSINESS_BANK_ACCOUNTS: '/reviews/business-bank-accounts',
  MOBILE_APP: '/mobile-app',
  LOGOUT: '/logout',
  COMPARISONS: '/comparisons',
  HK_INCORPORATION: '/hk-incorporation',
  COMPANY_LIST: '/company-list',
  COMPANY_PROFILE: '/company/profile/'
};
