/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import React from 'react';
import './src/styles/global.css';
import './src/styles/variable.scss';
import './src/styles/global.scss';
import './src/styles/typography.scss';
import './src/styles/buttons.scss';
import 'simplebar/src/simplebar.css';
import ThemeProvider from './src/theme';

import PrivateRoute from './src/components/privateRoutes/privateRoute';
import LoginAndSignUpRoute from './src/components/privateRoutes/LoginAndSignUpRoute';
import LogIn from './src/pages/login';
import SignUpStart from './src/pages/signup/start';
import { Router } from '@reach/router';
import { PATH_PAGE } from './src/routes/paths';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import CompanyIncorporation from './src/pages/company-set-up';
import CompanyList from './src/pages/company-list';
import CompanyIncorporationSuccess from './src/pages/company-incorporation-success';
import CompanySignUp from './src/pages/company-signup';
import CompanyProfile from './src/pages/company/profile';
import CompanyOrganization from './src/pages/company/organization';
import CompanyMailRoom from './src/pages/company/mailroom';
import CompanyDocuments from './src/pages/company/documents';
import CompanyProfileSetting from './src/pages/company/setting-profile';
import ConfirmEmailAccount from './src/pages/confirm-email-account';
import TooManyAttempts from './src/pages/too-many-attempts';
import ResetPassword from './src/pages/reset-password';
import VerifyEmail from './src/pages/verify-email';
import PublicCompanyDocs from './src/pages/public-company-docs';
import LandingPage from './src/pages/hk-incorporation';
import UnderMaintenance from './src/pages/under-maintenance';
import { STATUS } from './src/constants/enum';
import RenewPayment from './src/pages/renew-payment';
export { default as wrapRootElement } from './src/state/reduxWrapper';

export const onInitialClientRender = () => {
  document.getElementById('___gatsby').style.display = 'block';
};
export const wrapPageElement = () => {
  return (
    <ThemeProvider>
      {process.env.MAINTENANCE_FLOW_ENABLED === STATUS.TRUE ? (
        <Router>
          <UnderMaintenance default />
        </Router>
      ) : (
        <Router>
          <PrivateRoute path={PATH_PAGE.companyProfile} component={CompanyProfile} />
          <PrivateRoute path={PATH_PAGE.companyProfileSetting} component={CompanyProfileSetting} />
          <PrivateRoute path={PATH_PAGE.companyOrganization} component={CompanyOrganization} />
          <PrivateRoute path={PATH_PAGE.companyMailRoom} component={CompanyMailRoom} />
          <PrivateRoute path={PATH_PAGE.companyDocuments} component={CompanyDocuments} />
          <PrivateRoute path={PATH_PAGE.companyIncorporationSuccess} component={CompanyIncorporationSuccess} />
          <PrivateRoute path={PATH_PAGE.companiesList} component={CompanyList} />
          <PrivateRoute path={PATH_PAGE.renewPayment} component={RenewPayment} />
          <LoginAndSignUpRoute path={PATH_PAGE.login} component={LogIn} />
          <LoginAndSignUpRoute path={PATH_PAGE.confirmEmailAccount} component={ConfirmEmailAccount} />
          <LoginAndSignUpRoute path={PATH_PAGE.verifyEmail} component={VerifyEmail} />
          <LoginAndSignUpRoute path={PATH_PAGE.tooManyAttempts} component={TooManyAttempts} />
          <LoginAndSignUpRoute path={PATH_PAGE.resetPassword} component={ResetPassword} />
          <CompanySignUp path={PATH_PAGE.companySignUp} />
          <CompanyIncorporation path={PATH_PAGE.companyIncorporation} />

          <SignUpStart path={'/signup/start'} />
          <LandingPage path={PATH_PAGE.hkIncorporation} />
          <PublicCompanyDocs path={PATH_PAGE.publicCompanyDocs} />
          <LoginAndSignUpRoute path={PATH_PAGE.login} default component={LogIn} />
        </Router>
      )}
    </ThemeProvider>
  );
};
