import React, { useEffect } from 'react';
import SuccessImage from '../../images/icons/renewPayment/high-five-success-img.svg';
import BaseText from '../ui/typography/BaseText';
import XXSText from '../ui/typography/XXSText';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import { navigate } from 'gatsby';
import { getUser } from '../authService/auth';
import { getUserCompanies } from '../../helper/helpers';
import { ENDPOINTS } from '../../service/end-points';

function RenewalSuccessBanner({ companyDetails, setIsRenewalSuccessPage }) {
  const handleNavigation = () => {
    const companies = JSON?.parse(sessionStorage?.getItem('allCompanies'))?.companies;
    if (companies && companies?.length > 1) {
      navigate(ENDPOINTS?.COMPANY_LIST);
    } else {
      navigate(ENDPOINTS?.COMPANY_PROFILE);
    }
  };

  const user = getUser();
  async function refreshCompanyDetails() {
    await getUserCompanies(sessionStorage.getItem('token'), user?.email || companyDetails?.data?.data?.email);
    setIsRenewalSuccessPage(true);
  }
  useEffect(() => {
    refreshCompanyDetails();
  }, []);
  return (
    <div className="bg-white md:p-8 p-4 rounded-lg flex flex-col items-stretch justify-between renew-payment-form-container shadow-lg">
      <div className="flex flex-col items-center justify-between whitespace-pre-line h-[496px]">
        <img src={SuccessImage} className="w-[256px] h-[166px]" alt="Success Image" />
        <div className="flex flex-col gap-4">
          <BaseText
            fontSize="text-2xl"
            lineHeight={'leading-[100%]'}
            title={`Your payment for the renewal of ${companyDetails?.companyName} company has been successful!`}
          />

          <XXSText
            title={
              'We will now take care of the necessary steps to keep your company compliant. \n\nThank you for your continued trust in us. If you have any questions, feel free to reach out!'
            }
          />
        </div>
        <PrimaryButton
          id="success-page-button"
          caption="Go to dashboard"
          fontSize="text-base"
          className="my-2"
          onClick={handleNavigation}
          isBorderRequired={true}
          linkClass="w-full text-center"
          color="text-white"
          bgColor={'bg-green-500'}
        />
      </div>
    </div>
  );
}

export default RenewalSuccessBanner;
