import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import BackButton from '../ui/buttons/BackButton';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import * as userActions from '../account-setup/reduxStore/action';
import { connect } from 'react-redux';
import * as ActionsForRegistrationNewCompany from './redux/action';
import StepsIndicator from '../ui/StepsIndicator';
import XSText from '../ui/typography/XSText';
import PlusIcon from '../../images/icons/inline/plus.inline.svg';
import DropDown from '../common/DropDown';
import { StaticImage } from 'gatsby-plugin-image';
import SatisfactionGuaranteed from './components/SatisfactionGuaranteed';
import {
  COMPANY_REGISTRATION_COUNTRY,
  HONG_HONG_COMPANY_CREATION_ORIGINAL_PRICING,
  SINGAPORE_COMPANY_CREATION_ORIGINAL_PRICING,
  SINGAPORE_COMPANY_CREATION_FEES_DROPDOWN,
  HONG_KONG_COMPANY_CREATION_FEES_DROPDOWN,
  COMPANY_CREATE_STEPS,
  API_CODE
} from '../../constants/enum';

import { getUser, isLoggedIn } from '../authService/auth';
function Review({
  prevStep,
  dialCodes,
  countryDialCodes,
  nextStep,
  registrationCountry,
  setPayload,
  newCompanyAllInfo,
  companyCreate,
  completedSteps,
  setCompletedSteps,
  existingCompanyCreate,
  newCompanyData,
  companyId,
  companyUpdate
}) {
  const user = getUser();
  const currencyOfCompanyCreation =
    registrationCountry === COMPANY_REGISTRATION_COUNTRY?.HONG_KONG
      ? HONG_KONG_COMPANY_CREATION_FEES_DROPDOWN
      : SINGAPORE_COMPANY_CREATION_FEES_DROPDOWN;

  const originalPricing =
    registrationCountry === COMPANY_REGISTRATION_COUNTRY?.HONG_KONG
      ? HONG_HONG_COMPANY_CREATION_ORIGINAL_PRICING
      : SINGAPORE_COMPANY_CREATION_ORIGINAL_PRICING;
  const [isPricingDropDownOpen, setIsPricingDropDownOpen] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState(currencyOfCompanyCreation?.[0]);
  const [currencyOptionsToShow, setCurrencyOptionsToShow] = useState([]);

  const getCountryDialCodes = async () => {
    await countryDialCodes();
  };

  useEffect(() => {
    getCountryDialCodes();
    removeSelectedCurrencyFromOptions();
  }, []);
  function removeSelectedCurrencyFromOptions() {
    const otherTwoCurrencies = currencyOfCompanyCreation.slice(1, 3);
    setCurrencyOptionsToShow(otherTwoCurrencies);
  }

  function equiv() {
    return currencyOptionsToShow?.[0]?.price
      ? currencyOptionsToShow?.[0]?.title + currencyOptionsToShow?.[0]?.price
      : currencyOfCompanyCreation[1]?.title + currencyOfCompanyCreation[1]?.price;
  }

  let allCountriesCode = [];
  dialCodes?.forEach((each) => {
    let dialCodeObj = {};
    dialCodeObj['name'] = each.name;
    dialCodeObj['dialCode'] = each.dialCode;
    dialCodeObj['code'] = each.code;
    dialCodeObj['priority'] = each.priority;
    dialCodeObj['displayString'] = each.name + ' +' + each.dialCode;
    allCountriesCode.push(dialCodeObj);
  });

  let back = () => {
    isLoggedIn()
      ? setPayload((prev) => ({
          ...prev,
          step: COMPANY_CREATE_STEPS?.ACCOUNTING
        }))
      : prevStep();
  };

  const replaceCurrency = (optionValues) => {
    const selectedCurrency = currencyOptionsToShow.indexOf(optionValues);
    currencyOptionsToShow.splice(selectedCurrency, 1);
    setCurrencyOptionsToShow([...currencyOptionsToShow, selectedPrice]);
  };

  const selectOption = (optionValues) => {
    setSelectedPrice(optionValues);
    replaceCurrency(optionValues);
  };
  const checkDropdown = () => {
    setIsPricingDropDownOpen(!isPricingDropDownOpen);
  };

  async function moveToNextStep(apiResponse) {
    let payload = {
      companyId: apiResponse?.data?.data?._id,
      email: user.email
    };
    const response = await existingCompanyCreate(payload);
    if (response) {
      setCompletedSteps({
        ...completedSteps,
        personalInformationCompleted: true
      });
      nextStep();
    }
  }

  const onContinue = async () => {
    if (isLoggedIn()) {
      let newCompanyPayload = {
        ...newCompanyAllInfo,
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user.email
      };
      await newCompanyData(newCompanyPayload);
      let companyPayload = {
        companyName: newCompanyAllInfo?.companyName,
        numberOfOwners: newCompanyAllInfo?.selectedNumberOfOwners,
        industries: [newCompanyAllInfo?.industry],
        accountingAssistance: newCompanyAllInfo?.accounting,
        registrationCountry: newCompanyAllInfo?.registrationCountry,
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user.email,
        stakeholder: [
          {
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
            phoneNumber: user?.phoneNumber
          }
        ]
      };
      if (companyId) {
        companyPayload.companyId = companyId;
        const updateResponse = await companyUpdate(companyPayload);
        if (updateResponse?.status === API_CODE?.STATUS_200) {
          moveToNextStep(updateResponse);
        }
      } else {
        const createResponse = await companyCreate(companyPayload);
        if (createResponse?.status === API_CODE?.STATUS_200) {
          moveToNextStep(createResponse);
        }
      }
    } else {
      nextStep();
    }
  };

  return (
    <div className="bg-white md:p-8 p-4 rounded-lg flex flex-col items-stretch justify-between company-registration-form-container">
      <StepsIndicator numberOfSteps={5} currentStepNumber={3} />
      <div>
        <MDSubtitleText
          className="md:mt-10 mt-6 md:mb-8 mb-6"
          fontWeight="text-bold"
          title="Your incorporation package"
        />
        <XSText title="Incorporation Services" />
        <div>
          <div className="flex gap-2 items-center">
            <PlusIcon />
            <hr className="w-full border-t-1 border-green-200 my-3" />
          </div>
        </div>
        <XSText title="Company secretary services" />
        <div>
          <div className="flex gap-2 items-center">
            <PlusIcon />
            <hr className="w-full border-t-1 border-green-200 my-3" />
          </div>
        </div>
        <XSText title="Registered address" />
        <div>
          <div className="flex gap-2 items-center">
            {registrationCountry === COMPANY_REGISTRATION_COUNTRY.HONG_KONG ? <PlusIcon /> : null}
            <hr className="w-full border-t-1 border-green-200 my-3" />
          </div>
        </div>
        {registrationCountry === COMPANY_REGISTRATION_COUNTRY.HONG_KONG ? (
          <div>
            <XSText title="Business account opening" />
            <div>
              <div className="flex gap-2 items-center">
                <hr className="w-full  border-t-1 border-green-200 my-3" />
              </div>
            </div>
          </div>
        ) : null}
        <div className="w-full flex justify-center">
          <div className="flex mt-4 flex-row items-center md:gap-6 gap-2">
            {process.env.COMPANY_CREATION_DISCOUNT && (
              <StaticImage src="../../images/graphics/registerCompany/cc-discount.png" className="md:w-24 w-16" />
            )}
            <div className="flex flex-col items-center">
              {process.env.COMPANY_CREATION_DISCOUNT && (
                <div className="diagonal-border relative z-10">
                  <div className="flex justify-center z-1">
                    <p className=" text-bold text-gray-450 tracking-tighter">
                      {originalPricing?.find((pricing) => pricing?.title === selectedPrice?.title)?.title}
                    </p>
                    <p className="text-h3 text-bold text-gray-450 leading-8 tracking-tighter">
                      {originalPricing?.find((pricing) => pricing?.title === selectedPrice?.title)?.price}
                    </p>
                  </div>
                </div>
              )}
              <div className="flex flex-col items-center justify-start">
                <div className="flex gap-3 ">
                  <div className="flex flex-row">
                    <p className="hkd-package text-green-500">{selectedPrice?.title}</p>
                    <p className="business-account-price-package text-green-500">{selectedPrice?.price}</p>
                  </div>
                  <div
                    className="arrow-down-package flex self-center cursor-pointer"
                    onMouseEnter={checkDropdown}
                    onMouseLeave={checkDropdown}
                  >
                    {isPricingDropDownOpen && <DropDown options={currencyOptionsToShow} selectOption={selectOption} />}
                  </div>
                </div>
                <XSText title={`(equiv. of ${equiv()})`} className="ml-3" fontWeight="text-medium" />
              </div>
            </div>
          </div>
        </div>
        <SatisfactionGuaranteed className="md:block hidden" />
      </div>
      <div className="flex md:flex-row flex-col-reverse justify-between md:mt-8 mt-4 items-center w-full">
        <SatisfactionGuaranteed className="md:hidden block" />

        <PrimaryButton
          className="capitalize w-full onboarding-button"
          fontSize="text-base"
          caption="Checkout"
          onClick={onContinue}
          linkClass="w-full"
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    dialCodes: state?.AccountSetupInfo?.countryDialCodeList?.data,
    newCompanyAllInfo: state?.newAccountSetup?.newCompanyRegistrationData,
    companyId: state?.newAccountSetup?.companyCreateDetails?.data?._id
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    countryDialCodes: () => dispatch(userActions.countryDialCodes()),
    newCompanyData: (newCompanyRegistrationData) =>
      dispatch(ActionsForRegistrationNewCompany.newCompanyRegistrationData(newCompanyRegistrationData)),
    companyCreate: (companyCreateData) => dispatch(ActionsForRegistrationNewCompany.companyCreate(companyCreateData)),
    existingCompanyCreate: (companyCreateData) =>
      dispatch(ActionsForRegistrationNewCompany.existingCompanyCreate(companyCreateData)),
    companyUpdate: (companyUpdateData) => dispatch(ActionsForRegistrationNewCompany.companyUpdate(companyUpdateData))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Review);

Review.propTypes = {
  prevStep: PropTypes.func,
  countryDialCodes: PropTypes.func,
  dialCodes: PropTypes.array,
  nextStep: PropTypes.number,
  setCompletedSteps: PropTypes.object,
  completedSteps: PropTypes.object,
  registrationCountry: PropTypes.string,
  companyCreate: PropTypes.func,
  existingCompanyCreate: PropTypes.func,
  newCompanyData: PropTypes.func,
  companyUpdate: PropTypes.func
};
